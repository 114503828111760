<template>
  <main
    :class="menuCollapsed ? 'md:pl-[140px]' : 'md:pl-[284px]'"
    class="pl-6 pr-6 pt-[70px] pb-[140px] text-sm/[1.42] lg:text-lg/[1.38] duration-300"
  >
    <h1 class="flex items-end gap-x-6 mb-9 text-[40px] font-bold leading-[1.1] lg:text-[80px]">
      Roles
      <button
        v-if="checkPermission('users.create')"
        @click="roleAdd"
        class="group rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel p-2.5 lg:mb-3.5"
      >
        <img
          class="group-hover:scale-105 duration-300 mx-auto"
          src="@/assets/images/plusSquare.svg"
          alt="copyIcon"
        />
      </button>
    </h1>

    <!-- Adapt table for mobile devices -->
    <table class="grid w-full gap-y-4 text-center">
      <thead>
        <tr
          class="grid grid-cols-[repeat(3,_minmax(96px,_1fr)),_minmax(48px,_96px)] items-center gap-x-2 border-b border-silver-fog pb-1.5 text-ghost-gray"
        >
          <!-- COLUMN NAMES -->
          <th class="text-left font-normal">Name</th>
          <th class="font-normal">Permissions</th>
          <th class="font-normal">Action</th>
        </tr>
      </thead>
      <tbody class="space-y-2">
        <tr
          :class="{ 'absolute w-full h-full z-10 rounded-t-md bg-gray-600/20': requestTime }"
        ></tr>

        <!-- V-FOR -->
        <tr
          :key="roleIndex"
          v-for="(role, roleIndex) in roles"
          class="grid grid-cols-[repeat(3,_minmax(96px,_1fr)),_minmax(48px,_96px)] gap-x-2 rounded-md"
        >
          <td class="text-left">
            <p v-if="!role.edit">{{ role.name }}</p>
            <input
              class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
              :class="role.nameError ? 'outline outline-1 outline-red-600' : ''"
              @input="
                ({ target }) => {
                  role.nameError = target.value.length == 0 ? true : false
                }
              "
              type="text"
              v-else
              v-model="editRole.name"
            />
          </td>

          <td>
            <div class="w-full multiselect relative uppercase">
              <div
                @click="showMultiselectOptions"
                class="multiselectHeading cursor-pointer overflow-x-clip whitespace-nowrap rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 pl-3 pr-9"
              >
                <span>permissions</span>
                <div
                  class="absolute right-0 top-[calc(50%-12px)] my-auto dark:bg-shadowy-steel px-3"
                >
                  <img src="@/assets/images/arrow.svg" alt="arrowIcon" />
                </div>
              </div>
              <div
                class="options absolute left-0 top-[calc(100%_+_12px)] z-10 w-max min-w-full divide-y divide-midnight-ink rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel hidden"
                v-if="!role.edit"
              >
                <label
                  :key="permissionIndex"
                  v-for="(permission, permissionIndex) in role.permissions"
                  class="flex items-center gap-x-3 p-3"
                  :class="role.edit ? 'cursor-pointer' : ''"
                >
                  <span>{{ permission.name }}</span>
                </label>
              </div>
              <div
                class="options absolute left-0 top-[calc(100%_+_12px)] z-10 w-max min-w-full divide-y divide-midnight-ink rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel hidden"
                v-else
              >
                <label
                  :key="index"
                  v-for="(permission, index) in permissions"
                  class="flex items-center gap-x-3 p-3"
                  :class="role.edit ? 'cursor-pointer' : ''"
                >
                  <fwb-checkbox v-model="editRole.permissions[permission]" :label="permission" />
                </label>
              </div>
            </div>
          </td>

          <td class="flex justify-center items-center">
            <button
              class="flex flex-nowrap gap-1 justify-center rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5 m-2 w-[100px]"
              v-if="!role.edit && checkPermission('users.edit')"
              @click="roleEdit(role)"
            >
              <LoadSpinner v-if="role.load" />
              Edit
            </button>
            <button
              class="block rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5 m-2 w-[100px]"
              v-if="role.edit && !role.add && checkPermission('users.edit')"
              @click="roleEdit(role)"
            >
              Confirm
            </button>
            <button
              class="block rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5 m-2 w-[100px]"
              v-if="role.edit && !role.add && checkPermission('users.edit')"
              @click="roleEdit(role, true)"
            >
              Cancel
            </button>
            <button
              class="flex flex-nowrap gap-1 justify-center rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5 m-2 w-[100px]"
              v-if="!role.add && checkPermission('users.edit')"
              @click="roleDelete(role)"
            >
              <LoadSpinner v-if="role.loadRemove" />
              <p>Remove</p>
            </button>
            <button
              class="flex flex-nowrap gap-1 justify-center rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5 m-2 w-[100px]"
              v-if="role.edit && role.add && checkPermission('users.edit')"
              @click="roleSave"
            >
              <LoadSpinner v-if="role.load" />
              <p>Create</p>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </main>
</template>

<script setup>
import { inject, onBeforeMount, ref } from 'vue'
import { useFetcher } from '@/compose/axios'
import { useUserStore } from '@/stores/user'
import LoadSpinner from '@/components/custom/LoadSpinner.vue'
import { FwbCheckbox } from 'flowbite-vue'

const menuCollapsed = inject('menuCollapsed')
const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })

const userStore = useUserStore()

const checkPermission = (item) => {
  return userStore.checkPermission(item)
}

const requestTime = ref(false)

const roles = ref([])
const permissions = ref([])
const editRole = ref({
  id: null,
  name: null,
  permissions: {},

  add: false,
  load: false,
  loadRemove: false,
  edit: false,
  nameError: false
})

const showMultiselectOptions = (event) => {
  const currentMultiselectOptions = event.currentTarget.nextElementSibling

  document.querySelectorAll('.multiselect .options').forEach((multiselectOptions) => {
    if (multiselectOptions !== currentMultiselectOptions) multiselectOptions.classList.add('hidden')
  })

  currentMultiselectOptions.classList.toggle('hidden')
}

// Role Create (local)
const roleAdd = () => {
  if (!!roles.value.find((x) => x.add == true) == false) {
    roles.value.unshift({
      name: '',
      permissions: [],
      edit: true,
      add: true
    })
  }
}

// Role Delete
const roleDelete = (role) => {
  roles.value.map((x) => {
    x.loadRemove = x.id === role.id ? true : false
  })
  axiosInstance
    .delete('/admin/roles/' + role.id)
    .then((response) => {
      if (response?.data?.status == 'success') {
        roles.value = roles.value.filter((x) => {
          if (x.id == role.id) {
            return false
          }
          return true
        })
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

// Role Create (send data for server)
const roleSave = () => {
  if (roles.value[0].name == '' && roles.value[0].name == null) {
    roles.value[0].nameError = true
    return false
  }
  roles.value[0].load = true
  axiosInstance
    .post('/admin/roles', editRole.value)
    .then((response) => {
      if (response?.data?.status == 'success') {
        roles.value[0] = response?.data?.data
      }
      roles.value[0].load = false
    })
    .catch((error) => {
      console.log(error)
      roles.value[0].load = false
    })
}

//Role edit actions (Confirm, Cancel, Edit buttons)
const roleEdit = (role, cancel = false) => {
  if (!cancel) {
    if (role.edit) {
      // Confirm
      roles.value.map((x) => {
        x.edit = false
        if (x.id === role.id) {
          x.load = true
        }
      })

      axiosInstance
        .patch('/admin/roles/' + editRole.value.id, editRole.value)
        .then((response) => {
          if (response?.data?.status == 'success') {
            let data = response?.data?.data
            roles.value = roles.value.map((x) => {
              if (x.id === data.id) {
                x = data
              }
              return x
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      // Edit
      permissions.value.forEach((element) => {
        editRole.value.permissions[element] = false
      })
      roles.value.map((x) => {
        x.edit = x.id === role.id ? true : false
        if (x.id === role.id) {
          x.permissions.forEach((element) => {
            editRole.value.permissions[element.name] = true
          })
        }
      })

      editRole.value = {
        id: role.id,
        name: role.name,
        permissions: editRole.value.permissions
      }
    }
  } else {
    // Cancel
    roles.value.map((x) => {
      x.edit = false
    })
  }
}

onBeforeMount(() => {
  requestTime.value = true

  const hasEditPermission = checkPermission('users.edit') ? 'true' : 'false'

  // Fetch all roles with permissions
  axiosInstance
    .get('/admin/roles?edit=' + hasEditPermission)
    .then((response) => {
      if (response.data?.status == 'success') {
        roles.value = response?.data?.data?.roles
        if (response.data?.data?.permissions) {
          permissions.value = response.data?.data?.permissions
        }
      }
      requestTime.value = false
    })
    .catch((error) => {
      console.log(error)
      requestTime.value = false
    })
})
</script>
