import { defineStore } from 'pinia'
import { inject, ref, watch } from 'vue'

const STORE_NAME = 'main'

export const useMainStore = defineStore(STORE_NAME, () => {
  const storage = inject('storage')
  const getDefaultMode = () => {
    let mode = storage.getItem('mode')

    if (!mode) {
      return 'dark'
      // try {
      //     let isDark = window.matchMedia('(prefers-color-scheme:dark)').matches
      //     return isDark ? 'dark' : 'light'
      // } catch (e) {
      //     const date = new Date()
      //     const hour = date.getHours()
      //     if (hour >= '07' && hour < '17') {
      //         storage.setItem(MODE_LOCALSTORAGE_KEY, 'light', 'session')
      //         return 'light'
      //     } else {
      //         storage.setItem(MODE_LOCALSTORAGE_KEY, 'dark', 'session')
      //         return 'dark'
      //     }
      // }
    }

    return mode
  }
  const toggleDarkClass = () => {
    if (mode.value === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  const mode = ref(getDefaultMode())

  toggleDarkClass()

  watch(mode, toggleDarkClass)

  function $reset() {
    mode.value = null
  }

  function update(data) {
    mode.value = data
    storage.setItem('mode', data)
  }

  const loadDefaultMenu = () => {}

  loadDefaultMenu()
  return { mode, update, $reset }
})
