export function usehasClassInHierarchy() {
  const hasClassInHierarchy = (element, className) => {
    while (element) {
      if (element.classList.contains(className)) {
        return true
      }
      element = element.parentElement
    }
    return false
  }

  return { hasClassInHierarchy }
}
