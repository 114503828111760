<template>
  <ModeToggle class="m-4 ml-auto bg-[#ebebec] dark:bg-steel-gray" />
  <main
    class="flex h-screen w-screen items-center justify-center text-sm leading-[1.1] md:px-8 lg:text-lg"
  >
    <section class="bg-[#ebebec] dark:bg-steel-gray relative w-[720px] rounded-[40px] p-8 lg:p-12">
      <div
        class="bg-[#ebebec] dark:bg-steel-gray absolute -top-12 left-[calc(50%-49px)] rounded-t-full p-3 max-lg:scale-75"
      >
        <img src="@/assets/images/person.svg" alt="personIcon" />
      </div>
      <Form
        class="space-y-6"
        @submit="loginSubmit"
        :validation-schema="loginSchema"
        v-slot="{ errors }"
      >
        <div v-if="errors">{{ fillErrors(errors) }}</div>

        <div
          v-if="loginErrors"
          class="flex flex-col justify-end h-[52px] gap-y-2.5 lg:gap-y-3 text-[#CB444A] stroke-[#CB444A]"
        >
          <div v-for="(error, i) in loginErrors" :key="i" class="flex items-center gap-x-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              class="w-5 h-5 max-lg:scale-90"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              />
            </svg>
            <span>{{ error }}</span>
          </div>
        </div>
        <div class="bg-midnight-ink/10 dark:bg-midnight-ink grid gap-y-4 rounded-[40px] p-4">
          <Field name="email" v-slot="{ field }">
            <input
              class="bg-[#ebebec] dark:bg-steel-gray border-lush-green rounded-full border p-[22px] placeholder:text-black dark:placeholder:text-white text-sm lg:text-lg placeholder:text-sm lg:placeholder:text-lg"
              type="text"
              name="email"
              v-bind="field"
              @change="changeLoginSchemaTo('main')"
              placeholder="Login"
              required
            />
          </Field>
          <hr class="bg-lush-green mx-auto h-px w-1/4 rounded border-0" />
          <Field name="password" v-slot="{ field }">
            <input
              class="bg-[#ebebec] dark:bg-steel-gray border-lush-green rounded-full border p-[22px] placeholder:text-black dark:placeholder:text-white text-sm lg:text-lg placeholder:text-sm lg:placeholder:text-lg"
              type="password"
              v-bind="field"
              @change="changeLoginSchemaTo('main')"
              placeholder="Password"
              required
            />
          </Field>
        </div>
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-x-3">
            <input
              class="border-lush-green h-6 w-6 cursor-pointer appearance-none rounded-[5px] border bg-center bg-no-repeat duration-300 checked:bg-[url(@/assets/images/tick.svg)] checked:!bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
              type="checkbox"
              id="rememberMe"
            />
            <label class="cursor-pointer" for="rememberMe">Remember me</label>
          </div>
          <!--          <a-->
          <!--              class="text-lush-green font-semibold duration-300 hover:opacity-75"-->
          <!--              href="#"-->
          <!--          >Forgot password?</a-->
          <!--          >-->
        </div>
        <button
          :disabled="requestTime"
          type="submit"
          class="text-midnight-ink border-lush-green bg-lush-green w-full rounded-full border p-[22px] font-semibold duration-300 hover:bg-transparent dark:hover:text-white"
        >
          <LoadSpinner v-if="requestTime" />
          Sign in
        </button>
      </Form>
    </section>
  </main>
</template>

<script setup>
import { Field, Form } from 'vee-validate'
import * as yup from 'yup'
import { useUserStore } from '@/stores/user'
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import router from '@/router'
import LoadSpinner from '@/components/custom/LoadSpinner.vue'
import ModeToggle from '@/components/ModeToggle.vue'

const userStore = useUserStore()
const { isLoggedIn, requestTime, loginErrors } = storeToRefs(userStore)

const fillErrors = (errors) => {
  if (Object.keys(errors).length !== 0 && !loginErrors.value?.message) {
    loginErrors.value = errors
  }
}

const loginSchema = ref({})

const changeLoginSchemaTo = (typeOfSchema) => {
  loginSchema.value =
    typeOfSchema === 'main'
      ? yup.object({
          email: yup.string().email('Email must be a valid email'),
          password: yup
            .string()
            .max(25, 'Password is a maximum of 25 symbols')
            .min(6, 'Password is a minimum of 6 symbols')
        })
      : typeOfSchema === 'submit'
        ? yup.object({
            email: yup
              .string()
              .required('Email is a required field')
              .email('Email must be a valid email'),
            password: yup
              .string()
              .required('Password is a required field')
              .max(25, 'Password is a maximum of 25 symbols')
              .min(6, 'Password is a minimum of 6 symbols')
          })
        : ''
}

changeLoginSchemaTo('main')

const loginSubmit = (values) => {
  changeLoginSchemaTo('submit')
  setTimeout(() => {
    if (!loginErrors.value.email) {
      loginErrors.value = {}
      userStore.logIn(values)
    }
  }, 100)
}

onMounted(() => {
  if (isLoggedIn.value) {
    router.push({ name: 'home' })
  }
})
</script>

<style scoped></style>
