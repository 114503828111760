<template>
  <LeftSidebar v-if="isLoggedIn" />
  <ReloadPrompt />
  <router-view :key="$route.path" />
  <ProjectVersion />
</template>
<script setup>
import { ref, provide } from 'vue'

import { storeToRefs } from 'pinia'
import LeftSidebar from '@/components/LeftSidebar.vue'
import ProjectVersion from '@/components/ProjectVersion.vue'
import { useUserStore } from '@/stores/user'
import ReloadPrompt from '@/ReloadPrompt.vue'

const userStore = useUserStore()

const { isLoggedIn } = storeToRefs(userStore)

const menuCollapsed = ref(true)
provide('menuCollapsed', menuCollapsed)
</script>
