<script setup></script>

<template>
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23" cy="23" r="23" fill="#878787" />
    <mask
      id="mask0_1427_2060"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="46"
      height="46"
    >
      <circle cx="23" cy="23" r="23" fill="#878787" />
    </mask>
    <g mask="url(#mask0_1427_2060)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5474 18.5285C14.5474 13.8183 18.3657 10 23.0758 10C27.786 10 31.6043 13.8183 31.6043 18.5285C31.6043 23.2386 27.786 27.0569 23.0758 27.0569C18.3657 27.0569 14.5474 23.2386 14.5474 18.5285ZM6 38.5C6 33.2533 10.2533 29 15.5 29H30.5C35.7467 29 40 33.2533 40 38.5C40 43.7467 35.7467 48 30.5 48H15.5C10.2533 48 6 43.7467 6 38.5Z"
        fill="#4F5357"
      />
    </g>
  </svg>
</template>

<style scoped></style>
