<template>
  <main
    :class="menuCollapsed ? 'md:pl-[140px]' : 'md:pl-[284px]'"
    class="grid grid-cols-[max-content,_1fr] grid-rows-[repeat(4,_max-content)] pl-6 pr-6 pt-[70px] pb-[140px] text-sm/[1.42] lg:text-lg/[1.38] duration-300"
  >
    <section class="grid gap-y-6 row-span-full w-max h-max sticky top-16 mr-16">
      <label class="flex cursor-pointer items-center gap-x-3">
        <input
          :checked="filters.showArchived"
          @change="setShowArchived"
          class="h-6 w-6 cursor-pointer appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat duration-300 checked:bg-[url(@/assets/images/tick.svg)] checked:!bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
          type="checkbox"
        />
        <span>Show archived</span>
      </label>
      <hr class="h-px border-none bg-silver-fog rounded-full" />
      <div class="grid gap-y-6">
        <label v-for="car in cars" :key="car.id" class="flex cursor-pointer items-center gap-x-3">
          <input
            :checked="checkedCar(car.class_id)"
            @change="carCheck(car.class_id)"
            class="h-6 w-6 cursor-pointer appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat duration-300 checked:bg-[url(@/assets/images/tick.svg)] checked:!bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
            type="checkbox"
          />
          <span>{{ car.class_name }}</span>
        </label>
      </div>
    </section>
    <FullCalendar ref="fullCalendar" :options="calendarOptions" />
  </main>
  <!-- Popup -->
  <div
    v-if="isShowModal"
    @click.self="closeModal"
    class="overflow-y-hidden overflow-x-hidden fixed top-0 left-0 z-50 flex justify-center items-center size-full md:inset-0 backdrop-blur-sm backdrop-brightness-50"
  >
    <div
      class="w-full h-max max-h-screen max-w-6xl lg:max-h-[90vh] lg:w-[70%] z-50 bg-white dark:bg-[#2B2D32] border rounded-[40px] border-[#878787] mx-auto p-10 gap-4 flex flex-col text-[#2B2D32] dark:text-white overflow-y-auto"
    >
      <div class="flex justify-between">
        <div class="flex flex-col gap-4">
          <div class="text-2xl">{{ eventPopupData.title }}</div>
          <div class="text-[#878787] text-lg flex">
            {{ eventPopupData.date.start }} – {{ eventPopupData.date.end }}
          </div>
        </div>
        <div @click="closeModal" class="cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
          >
            <g clip-path="url(#clip0_3282_2928)">
              <path
                class="fill-[#2B2D32] dark:fill-white"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M63.2 32C63.2 49.2313 49.2313 63.2 32 63.2C14.7687 63.2 0.8 49.2313 0.8 32C0.8 14.7687 14.7687 0.8 32 0.8C49.2313 0.8 63.2 14.7687 63.2 32ZM64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32ZM39.9682 22.9014L41.0989 24.032L33.132 32.0001L41.0989 39.9681L39.9682 41.0987L32.0002 33.1318L24.0322 41.0987L22.9016 39.9681L30.8685 32.0001L22.9016 24.032L24.0322 22.9014L32.0002 30.8683L39.9682 22.9014Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_3282_2928">
                <rect width="64" height="64" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div class="text-xl">Now:</div>
      <table
        class="grid w-full border border-[#878787] rounded-md text-lg pb-7 gap-y-4 overflow-x-auto min-h-[375px]"
      >
        <thead>
          <tr
            class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5 border-b border-[#878787] px-4 pt-2 pb-4"
          >
            <th class="font-normal text-left">Parameters</th>
            <th
              class="font-normal text-center"
              v-for="(car, index) in eventPopupData.cars"
              :key="index"
            >
              {{ getCarNameById(car.class_id) }}
            </th>
          </tr>
        </thead>
        <tbody class="space-y-1.5 pl-5 pr-5">
          <tr
            class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
          >
            <td class="text-left">Status</td>
            <td
              v-for="(car, index) in eventPopupData.cars"
              :key="index"
              class="text-center text-white rounded-md bg-[#333639]"
            >
              <input
                :checked="car.car_on_off === 1"
                class="pointer-events-none size-4.5 disabled:bg-silver-fog appearance-none rounded-[4px] border border-lush-green bg-center bg-no-repeat p-0 duration-300 checked:bg-[url(@/assets/images/tick.svg)] checked:!bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                type="checkbox"
              />
            </td>
          </tr>
          <tr
            class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
          >
            <td class="text-left">Basic Exit Fee</td>
            <td
              v-for="(car, index) in eventPopupData.cars"
              :key="index"
              class="text-center text-white rounded-md bg-[#333639]"
            >
              {{
                applyCoefficient(
                  eventPopupData.cars.find(
                    (obj) => obj.class_id === getCorrectClassId(car.class_id)
                  ).basic_exit_fee
                )
              }}
            </td>
          </tr>
          <tr
            class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
          >
            <td class="text-left">Last Minute Fee</td>
            <td
              v-for="(car, index) in eventPopupData.cars"
              :key="index"
              class="text-center text-white rounded-md bg-[#333639]"
            >
              {{
                applyCoefficient(
                  eventPopupData.cars.find(
                    (obj) => obj.class_id === getCorrectClassId(car.class_id)
                  ).last_minute_fee
                )
              }}
            </td>
          </tr>
          <tr
            class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
          >
            <td class="text-left">Last Second Fee</td>
            <td
              v-for="(car, index) in eventPopupData.cars"
              :key="index"
              class="text-center text-white rounded-md bg-[#333639]"
            >
              {{
                applyCoefficient(
                  eventPopupData.cars.find(
                    (obj) => obj.class_id === getCorrectClassId(car.class_id)
                  ).last_second_fee
                )
              }}
            </td>
          </tr>
          <tr
            class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
          >
            <td class="text-left">Price per km</td>
            <td
              v-for="(car, index) in eventPopupData.cars"
              :key="index"
              class="text-center text-white rounded-md bg-[#333639]"
            >
              {{
                applyCoefficient(
                  eventPopupData.cars.find(
                    (obj) => obj.class_id === getCorrectClassId(car.class_id)
                  ).price_per_km
                )
              }}
            </td>
          </tr>
          <tr
            class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
          >
            <td class="text-left">Price per hour</td>
            <td
              v-for="(car, index) in eventPopupData.cars"
              :key="index"
              class="text-center text-white rounded-md bg-[#333639]"
            >
              {{
                applyCoefficient(
                  eventPopupData.cars.find(
                    (obj) => obj.class_id === getCorrectClassId(car.class_id)
                  ).price_per_hr
                )
              }}
            </td>
          </tr>
          <tr
            class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
          >
            <td class="text-left">Extra km rate</td>
            <td
              v-for="(car, index) in eventPopupData.cars"
              :key="index"
              class="text-center text-white rounded-md bg-[#333639]"
            >
              {{
                applyCoefficient(
                  eventPopupData.cars.find(
                    (obj) => obj.class_id === getCorrectClassId(car.class_id)
                  ).extra_km_rate
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="coefficientPercentageAsDecimal !== undefined && eventPopupData.laterCarsIds.length"
        class="space-y-4"
      >
        <div class="text-xl">Later:</div>
        <table
          class="grid w-full border border-[#878787] rounded-md text-lg pb-7 gap-y-4 overflow-x-auto min-h-[375px]"
        >
          <thead>
            <tr
              class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5 border-b border-[#878787] px-4 pt-2 pb-4"
            >
              <th class="font-normal text-left">Parameters</th>
              <th
                class="font-normal text-center"
                v-for="classId in eventPopupData.laterCarsIds"
                :key="classId"
              >
                {{ getCarNameById(classId) }}
              </th>
            </tr>
          </thead>
          <tbody class="space-y-1.5 pl-5 pr-5">
            <tr
              class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
            >
              <td class="text-left">Status</td>
              <td
                v-for="(car, index) in eventPopupData.cars"
                :key="index"
                class="text-center text-white rounded-md bg-[#333639]"
              >
                <input
                  :checked="car.car_on_off === 1"
                  class="pointer-events-none size-4.5 disabled:bg-silver-fog appearance-none rounded-[4px] border border-lush-green bg-center bg-no-repeat p-0 duration-300 checked:bg-[url(@/assets/images/tick.svg)] checked:!bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                  type="checkbox"
                />
              </td>
              <!-- <td  another worker  chekbox
                v-for="(car, index) in eventPopupData.laterCarsIds"
                :key="'car-' + index"
                class="text-center text-white rounded-md bg-[#333639]"
              >
              -->
              <!-- <input   another worker  chekbox
                  v-model="car.car_on_off"
                  checked
                  class="size-4.5 rounded-[4px] border border-lush-green bg-center bg-no-repeat p-0 duration-300 checked:bg-[url(@/assets/images/tick.svg)] checked:!bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                  type="checkbox"
                /> -->
            </tr>
            <tr
              class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
            >
              <td class="text-left">Basic Exit Fee</td>
              <td
                v-for="classId in eventPopupData.laterCarsIds"
                :key="classId"
                class="text-center text-white rounded-md bg-[#333639]"
              >
                {{
                  applyCoefficient(
                    eventPopupData.cars.find((obj) => obj.class_id === getCorrectClassId(classId))
                      .basic_exit_fee
                  )
                }}
              </td>
            </tr>
            <tr
              class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
            >
              <td class="text-left">Last Minute Fee</td>
              <td
                v-for="classId in eventPopupData.laterCarsIds"
                :key="classId"
                class="text-center text-white rounded-md bg-[#333639]"
              >
                {{
                  applyCoefficient(
                    eventPopupData.cars.find((obj) => obj.class_id === getCorrectClassId(classId))
                      .last_minute_fee
                  )
                }}
              </td>
            </tr>
            <tr
              class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
            >
              <td class="text-left">Last Second Fee</td>
              <td
                v-for="classId in eventPopupData.laterCarsIds"
                :key="classId"
                class="text-center text-white rounded-md bg-[#333639]"
              >
                {{
                  applyCoefficient(
                    eventPopupData.cars.find((obj) => obj.class_id === getCorrectClassId(classId))
                      .last_second_fee
                  )
                }}
              </td>
            </tr>
            <tr
              class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
            >
              <td class="text-left">Price per km</td>
              <td
                v-for="classId in eventPopupData.laterCarsIds"
                :key="classId"
                class="text-center text-white rounded-md bg-[#333639]"
              >
                {{
                  applyCoefficient(
                    eventPopupData.cars.find((obj) => obj.class_id === getCorrectClassId(classId))
                      .price_per_km
                  )
                }}
              </td>
            </tr>
            <tr
              class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
            >
              <td class="text-left">Price per hour</td>
              <td
                v-for="classId in eventPopupData.laterCarsIds"
                :key="classId"
                class="text-center text-white rounded-md bg-[#333639]"
              >
                {{
                  applyCoefficient(
                    eventPopupData.cars.find((obj) => obj.class_id === getCorrectClassId(classId))
                      .price_per_hr
                  )
                }}
              </td>
            </tr>
            <tr
              class="grid items-center grid-cols-[minmax(197.5px,_1fr),_repeat(6,_minmax(109px,_1fr))] gap-x-1.5"
            >
              <td class="text-left">Extra km rate</td>
              <td
                v-for="classId in eventPopupData.laterCarsIds"
                :key="classId"
                class="text-center text-white rounded-md bg-[#333639]"
              >
                {{
                  applyCoefficient(
                    eventPopupData.cars.find((obj) => obj.class_id === getCorrectClassId(classId))
                      .extra_km_rate
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useFetcher } from '@/compose/axios'
import centrifugo from '@/plugins/centrifugo'
import { useAreasStore } from '@/stores/areas'
import { useCarsStore } from '@/stores/cars'
import { useCentrifugoStore } from '@/stores/centrifugo'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import FullCalendar from '@fullcalendar/vue3'
import { collect } from 'collect.js'
import moment from 'moment/moment'
import { storeToRefs } from 'pinia'
import { computed, inject, onMounted, ref, watch } from 'vue'

const isShowModal = ref(false)

const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
const menuCollapsed = inject('menuCollapsed')

const carsStore = useCarsStore()
const areasStore = useAreasStore()

const { areas } = storeToRefs(areasStore)
const { cars } = storeToRefs(carsStore)

const discounts = ref(null)

const filters = ref({
  showArchived: false,
  carsChecked: []
})

function closeModal() {
  isShowModal.value = false
}

function showModal() {
  isShowModal.value = true
}

const events = computed(() => {
  let discountsCollect = collect(discounts.value)

  if (!filters.value.showArchived) {
    discountsCollect = discountsCollect.filter((discount) => discount.status !== 0)
  }

  if (filters.value.carsChecked.length) {
    discountsCollect = discountsCollect.filter((discount) => {
      let tempResult = collect(discount.cars_array).intersect(filters.value.carsChecked)
      return !tempResult.isEmpty()
    })
  }

  const keyed = discountsCollect.transform((item) => {
    const startDate = new Date(item.start_date)
    const endDate = new Date(item.end_date)
    const duration = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24))

    let eventColor = ''
    if (duration <= 1) eventColor = 'red'
    else if (duration <= 2) eventColor = 'yellow'
    else if (duration <= 7) eventColor = 'orange'
    else eventColor = 'red'

    return {
      title: makeTitle(item),
      start: item.start_date,
      end: item.end_date,
      duration,
      color: eventColor
    }
  })
  return keyed.toArray()
})

watch(
  filters,
  (/*newVal, oldVal*/) => {
    calendarOptions.value.events = events.value
  },
  { deep: true }
)

const eventPopupData = ref({
  title: '',
  date: {
    start: '',
    end: ''
  },
  cars: [],
  discountCars: [],
  laterCarsIds: []
})

const getCarNameById = (carId) => {
  switch (carId) {
    case 1:
      return 'E-class'
    case 2:
      return 'V-class'
    case 3:
      return 'Sprinter'
    case 4:
      return 'S-class'
    case 5:
      return 'GLS-class'
    case 6:
      return 'Sprinter-Long'
    default:
      return ''
  }
}

const getCorrectClassId = (classId) => {
  switch (classId) {
    case 4:
      return 5
    case 5:
      return 6
    case 6:
      return 4
    default:
      return classId
  }
}

const coefficientPercentageAsDecimal = computed(() => {
  return eventPopupData.value.title.match(/(\+|-)\d+%/g)?.[0].replace('%', '') / 100
})

const applyCoefficient = (value) => {
  if (coefficientPercentageAsDecimal.value !== undefined) {
    return parseFloat((value + value * coefficientPercentageAsDecimal.value).toFixed(1))
  }
}

const calendarOptions = ref({
  plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
  initialView: 'dayGridMonth',
  headerToolbar: {
    left: 'dayGridMonth,timeGridDay,timeGridWeek,prev,next',
    center: 'title',
    right: ''
  },
  eventContent: function (arg) {
    const eventInfo = arg.event
    return {
      html: eventInfo.title
    }
  },
  eventClick: async (event) => {
    eventPopupData.value.title = event.el.innerText
    eventPopupData.value.date.start = moment(event.event.start).format('DD/MM/YYYY H:mm')
    eventPopupData.value.date.end = moment(event.event.end).format('DD/MM/YYYY H:mm')

    await axiosInstance
      .post(`/admin/discounts/archive_params/${eventPopupData.value.title.split(' ')[1]}`)
      .then((response) => {
        if (response.data.data.length) {
          console.log('Response from server:', response)

          eventPopupData.value.cars = response.data.data
          console.log(eventPopupData.value.cars)
          const discountId = parseInt(eventPopupData.value.title.split(' ')[1])
          const later_cars = discounts.value.find((obj) => obj.id === discountId)

          eventPopupData.value.laterCarsIds = later_cars.cars_array

          showModal()
        } else {
          console.log("There're no discount parameters in the response.")
        }
      })
  }
})

const fullCalendar = ref(null)

const setShowArchived = () => {
  filters.value.showArchived = !filters.value.showArchived
}
const checkedCar = (id) => {
  return filters.value.carsChecked.includes(id)
}

const carCheck = (id) => {
  if (checkedCar(id)) {
    filters.value.carsChecked = filters.value.carsChecked.filter((e) => e !== id)
  } else {
    filters.value.carsChecked.push(id)
  }
}
const getTitleStyle = (discountValue) => {
  if (discountValue <= 0) return 'white'
  else if (discountValue <= 20) return 'light-green'
  else if (discountValue <= 50) return 'green'
  else return 'dark-green'
}

const makeTitle = (discount) => {
  let titleString = 'Prog'
  titleString += ': ' + discount.id + ' '

  titleString += makeAreasTitle(discount)
  titleString += ' ' + (discount.plus_minus === 'plus' ? '+' : '-')
  titleString += discount.discount_value + (discount.type ? '€' : '%')

  titleString += makeCarsTitle(discount)

  titleString += discount.status === 0 ? '(Archived)' : ''

  return `<span class=${getTitleStyle(discount.discount_value)}>${titleString}</span>`
}

const makeAreasTitle = (discount) => {
  let titleString = ' '
  let tempArray = []
  discount.areas_array.forEach((e) => {
    let area = collect(areas.value).first((a) => a.id === e)
    if (area) {
      tempArray.push(area.area_short_name)
    }
  })

  titleString += ' ' + tempArray.join(',')
  return titleString
}

const makeCarsTitle = (discount) => {
  let titleString = ' '
  let tempArray = []
  discount.cars_array.forEach((e) => {
    let car = collect(cars.value).first((c) => c.class_id === e)
    tempArray.push(car.short_name)
  })

  titleString += ' ' + tempArray.join(',')
  return titleString
}

onMounted(() => {
  axiosInstance.post('/admin/discounts/callendar').then((response) => {
    discounts.value = response.data.data

    calendarOptions.value.events = events.value
  })
})

const centrifugoStore = useCentrifugoStore()

centrifugoStore.getToken().then((centrifugoToken) => {
  centrifugo.connect('as.discounts.updated', 'Discounts.Table.Updated', centrifugoToken, (data) => {
    discounts.value = data.discounts
    calendarOptions.value.events = events.value
  })
})
</script>
<style>
.fc-button {
  background-color: yellow;
  color: yellow;
}

.fc-button-primary {
  background-color: yellow;
  color: yellow;
}
.white {
  color: white;
}
.light-green {
  color: lightgreen;
}
.green {
  color: green;
}
.dark-green {
  color: darkgreen;
}
.fc-event {
  cursor: pointer;
}
</style>
