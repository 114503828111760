<template>
  <input
    v-model="modalData.latitude"
    placeholder="latitude"
    class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
  />
  <input
    v-model="modalData.longitude"
    placeholder="longitude"
    class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
  />
  <input
    v-model="modalData.radius"
    placeholder="radius (km)"
    class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
  />
</template>

<script setup>
import { useModalStore } from '@/stores/modal'
import { storeToRefs } from 'pinia'

const modalStore = useModalStore()

const { modalData } = storeToRefs(modalStore)
</script>

<style scoped></style>
