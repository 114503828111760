<script setup>
import { inject } from 'vue'

const menuCollapsed = inject('menuCollapsed')
</script>

<template>
  <svg
    :class="menuCollapsed ? 'rotate-[30deg] md:rotate-[60deg]' : '-rotate-[30deg] md:rotate-0'"
    class="stroke-black dark:stroke-white duration-300"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1427_2118)">
      <circle
        cx="16"
        cy="16"
        r="13"
        transform="rotate(-180 16 16)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.1113 16L17.4447 13.4982L17.4447 18.5019L13.1113 16Z"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1427_2118">
        <rect width="32" height="32" transform="translate(32 32) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped></style>
