<template>
  <main
    :class="menuCollapsed ? 'md:pl-[140px]' : 'md:pl-[284px]'"
    class="pl-6 pr-6 pt-[70px] pb-[140px] text-sm/[1.42] lg:text-lg/[1.38] duration-300"
  >
    <h1 class="flex items-end gap-x-6 mb-9 text-[40px] font-bold leading-[1.1] lg:text-[80px]">
      Users
      <button
        v-if="checkPermission('users.create')"
        @click="userAdd"
        class="group rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel p-2.5 lg:mb-3.5"
      >
        <img
          class="group-hover:scale-105 duration-300 mx-auto"
          src="@/assets/images/plusSquare.svg"
          alt="copyIcon"
        />
      </button>
    </h1>
    <section>
      <div class="overflow-hidden" id="headerdiv">
        <table id="headertable" class="w-full">
          <thead>
            <!-- Overlay -->
            <tr
              :class="{ 'absolute w-full h-full z-10 rounded-t-md bg-gray-600/20': requestTime }"
            ></tr>
            <tr>
              <th @click="setOrder('id')" class="cursor-pointer font-normal text-[#878787]">
                Prod ID
                <span v-if="filters.order_by === 'id'">
                  <sup v-if="filters.order_type">↑</sup>
                  <sup v-if="!filters.order_type">↓</sup>
                </span>
              </th>
              <th @click="setOrder('first_name')" class="cursor-pointer font-normal text-[#878787]">
                First Name
                <span v-if="filters.order_by === 'first_name'">
                  <sup v-if="filters.order_type">↑</sup>
                  <sup v-if="!filters.order_type">↓</sup>
                </span>
              </th>
              <th @click="setOrder('last_name')" class="cursor-pointer font-normal text-[#878787]">
                Last Name
                <span v-if="filters.order_by === 'last_name'">
                  <sup v-if="filters.order_type">↑</sup>
                  <sup v-if="!filters.order_type">↓</sup>
                </span>
              </th>
              <th @click="setOrder('email')" class="cursor-pointer font-normal text-[#878787]">
                Email
                <span v-if="filters.order_by === 'email'">
                  <sup v-if="filters.order_type">↑</sup>
                  <sup v-if="!filters.order_type">↓</sup>
                </span>
              </th>
              <th class="font-normal text-[#878787]">Permissions</th>
              <th class="font-normal text-[#878787]">Actions</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        class="overflow-y-scroll max-h-[100vh]"
        onscroll="document.getElementById('headerdiv').scrollLeft = this.scrollLeft;"
      >
        <table id="bodytable" class="w-full">
          <tbody class="divide-y divide-silver-fog text-center">
            <tr class="gap-x-2">
              <td>
                <input
                  v-model="filters.id"
                  class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                  placeholder="ID"
                  type="number"
                  min="1"
                />
              </td>
              <td>
                <input
                  v-model="filters.first_name"
                  class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                  placeholder="First name"
                  type="text"
                />
              </td>
              <td>
                <input
                  v-model="filters.last_name"
                  class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                  placeholder="Last name"
                  type="text"
                />
              </td>
              <td>
                <input
                  v-model="filters.email"
                  class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                  placeholder="Email"
                  type="email"
                />
              </td>
              <td>
                <div class="w-full multiselect relative uppercase">
                  <div
                    @click="showMultiselectOptions"
                    class="multiselectHeading cursor-pointer overflow-x-clip whitespace-nowrap rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 pl-3 pr-9"
                  >
                    <span>permissions</span>
                    <div
                      class="absolute right-0 top-[calc(50%-12px)] my-auto dark:bg-shadowy-steel px-3"
                    >
                      <img src="@/assets/images/arrow.svg" alt="arrowIcon" />
                    </div>
                  </div>
                  <div
                    class="options absolute left-0 top-[calc(100%_+_12px)] z-10 w-max min-w-full divide-y divide-midnight-ink rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel hidden"
                  >
                    <label
                      :key="index"
                      v-for="(permission, index) in filtersOptions.permissions"
                      class="flex cursor-pointer items-center gap-x-3 p-3"
                    >
                      <fwb-checkbox
                        v-model="filters.permissions[permission]"
                        :label="permissionUsers([{ name: permission }], true)"
                      />
                    </label>
                  </div>
                </div>
              </td>
              <td class="flex flex-row gap-1">
                <button
                  @click="filterUsers"
                  class="block w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5"
                >
                  <img
                    class="group-hover:scale-105 duration-300 mx-auto"
                    src="@/assets/images/magnifyingGlass.svg"
                    alt="magnifyingGlassIcon"
                  />
                </button>
                <button
                  @click="clearFilters"
                  class="block w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5"
                >
                  <img
                    class="group-hover:scale-105 duration-300 mx-auto"
                    src="@/assets/images/crossedMagnifyingGlass.svg"
                    alt="crossedMagnifyingGlassIcon"
                  />
                </button>
              </td>
            </tr>
            <tr v-for="(user, index) in users" :key="index">
              <td>{{ user.id }}</td>
              <td>{{ user.first_name }}</td>
              <td>{{ user.last_name }}</td>
              <td>{{ user.email }}</td>
              <td v-html="permissionUsers(user.permissions)"></td>
              <td class="flex justify-center">
                <router-link
                  v-if="checkPermission('users.edit')"
                  :to="{ name: 'profile', params: { id: user.id } }"
                  class="block rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5 m-2 w-[100px]"
                >
                  Edit
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <fwb-pagination
          v-model="meta.current_page"
          :total-items="meta.total"
          :totalPages="meta.last_page"
          :perPage="meta.per_page"
          :showLabels="false"
          show-icons
          @click="paginationBarClicked"
        ></fwb-pagination>
      </div>
    </section>
  </main>
</template>

<script setup>
import { inject, onBeforeMount, ref } from 'vue'
import { useFetcher } from '@/compose/axios'
import { collect } from 'collect.js'
import { useUserStore } from '@/stores/user'
import router from '@/router'
import { FwbCheckbox, FwbPagination } from 'flowbite-vue'
import { usehasClassInHierarchy } from '@/compose/hasClassInHierarchy'

const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
const menuCollapsed = inject('menuCollapsed')
const userStore = useUserStore()
const checkPermission = (item) => {
  return userStore.checkPermission(item)
}
const users = ref(null)

const showMultiselectOptions = (event) => {
  const currentMultiselectOptions = event.currentTarget.nextElementSibling

  document.querySelectorAll('.multiselect .options').forEach((multiselectOptions) => {
    if (multiselectOptions !== currentMultiselectOptions) multiselectOptions.classList.add('hidden')
  })

  currentMultiselectOptions.classList.toggle('hidden')
}

const { hasClassInHierarchy } = usehasClassInHierarchy()

const hideAllMultiselectOptions = (event) => {
  if (!hasClassInHierarchy(event.target, 'multiselect')) {
    document.querySelectorAll('.multiselect .options').forEach((multiselectOptions) => {
      multiselectOptions.classList.add('hidden')
    })
  }
}

const filters = ref({
  id: null,
  first_name: null,
  last_name: null,
  email: null,
  permissions: [],
  order_by: 'id',
  order_type: false
})

const meta = ref({
  current_page: 1,
  from: null,
  last_page: null,
  path: null,
  per_page: null,
  to: null,
  total: null
})

const requestTime = ref(false)

const permissionNames = {
  home: 'Calculator',
  areas: 'Areas',
  rates: 'Rates',
  discounts: 'Discounts',
  callendar: 'Calendar',
  users: 'Users',
  'ride-history-requests': 'History Requests',
  audit: 'Audit'
}

const setOrder = (orderBy) => {
  filters.value.order_by = orderBy
  filters.value.order_type = !filters.value.order_type

  filterUsers()
}

const clearFilters = () => {
  filters.value = {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    permissions: {},
    order_by: 'id',
    order_type: false
  }

  filterUsers()
}

const filterUsers = async () => {
  let payload = {}

  payload.page = meta.value.current_page
  payload.per_page = 10

  if (filters.value.id) {
    payload.id = filters.value.id
  }

  if (filters.value.first_name) {
    payload.first_name = filters.value.first_name
  }

  if (filters.value.email) {
    payload.email = filters.value.email
  }

  if (filters.value.last_name) {
    payload.last_name = filters.value.last_name
  }

  if (filters.value.order_by && typeof filters.value.order_type === 'boolean') {
    payload.sort_by = filters.value.order_by
    payload.sort_type = filters.value.order_type ? 'asc' : 'desc'
  }

  let tempArr = []
  const perm = filters.value.permissions
  Object.keys(perm).forEach((key) => {
    if (perm[key] === true) {
      tempArr.push(key)
    }
  })

  if (tempArr.length > 0) {
    payload.permissions = tempArr
  }

  requestTime.value = true

  axiosInstance
    .post('/admin/users', payload)
    .catch((error) => {
      console.log(error)
      users.value = []
      requestTime.value = false
    })
    .then((response) => {
      const data = response.data

      users.value = response.data.data

      meta.value.current_page = data.meta.current_page
      meta.value.from = data.meta.from
      meta.value.last_page = data.meta.last_page
      meta.value.per_page = data.meta.per_page
      meta.value.to = data.meta.to
      meta.value.total = data.meta.total
      meta.value.path = data.meta.path

      requestTime.value = false
    })

  axiosInstance.get('/admin/users/filters').then((response) => {
    filtersOptions.value.emails = response.data.data.emails
    filtersOptions.value.permissions = response.data.data.permissions
  })
}

const userAdd = () => {
  router.push({ name: 'profile_create' })
}

const filtersOptions = ref({
  emails: [],
  permissions: []
})

const paginationBarClicked = async () => {
  await filterUsers()
}

onBeforeMount(() => filterUsers())

const permissionUsers = (permissions, multiselect = false) => {
  const permArray = collect(permissions).pluck('name')

  let permissionPrepare = {}
  permArray.each((e) => {
    let splitString = e.split('.')
    if (Object.prototype.hasOwnProperty.call(permissionPrepare, splitString[0])) {
      permissionPrepare[splitString[0]].push(splitString[1].toUpperCase())
    } else {
      permissionPrepare[splitString[0]] = [splitString[1].toUpperCase()]
    }
  })
  let permissionString = ''

  for (const [key, value] of Object.entries(permissionNames)) {
    if (permissionPrepare[key]) {
      if (!multiselect) {
        permissionString += value + ' (' + permissionPrepare[key].join(',') + ') <br />'
      } else {
        permissionString += value + ' (' + permissionPrepare[key].join(',') + ')'
      }
    }
  }

  return permissionString
}

window.addEventListener('click', hideAllMultiselectOptions)
</script>

<style scoped>
table tbody tr:nth-child(2n) {
  background-color: #2d3135;
}
</style>
