import './assets/main.css'

import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router/index.js'
import storage from '@/plugins/storage'
import appAxios from '@/plugins/axios'
import utils from '@/plugins/utils'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import PrimeVue from 'primevue/config'

const pinia = createPinia()
const app = createApp(App)
/**
 * Plugin install
 */
app.use(router)
app.use(pinia)
app.use(appAxios, {
  baseUrl: import.meta.env.VITE_APP_API_URL
})
app.use(PrimeVue)
/**
 * Provides install
 */
app.provide('storage', storage)
app.provide('utils', utils)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_APP_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^.*trustyone\.dev/,
        import.meta.env.VITE_APP_PRODUCTION_URL
      ],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.mount('#app')
