<template>
  <main
    :class="menuCollapsed ? 'md:pl-[140px]' : 'md:pl-[284px]'"
    class="pl-6 pr-6 pt-[70px] pb-[140px] text-sm/[1.42] lg:text-lg/[1.38] duration-300"
  >
    <h1 class="mb-9 text-[40px] font-bold leading-[1.1] lg:text-[80px]">Audit Log</h1>

    <section class="space-y-8">
      <div class="overflow-hidden" id="headerdiv">
        <table id="headertable" class="w-full">
          <thead>
            <tr
              class="grid grid-cols-[repeat(6,_minmax(96px,_1fr)),_minmax(48px,_96px)] items-center gap-x-2 border-b border-silver-fog pb-1.5 text-ghost-gray"
            >
              <th class="font-normal">Causer</th>
              <th class="font-normal">Action</th>
              <th class="font-normal">Subject</th>
              <th class="font-normal">Logged At</th>
              <th class="font-normal">Diff</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        class="overflow-y-scroll max-h-[100vh]"
        onscroll="document.getElementById('headerdiv').scrollLeft = this.scrollLeft;"
      >
        <table id="bodytable" class="grid w-full gap-y-4 text-center">
          <tbody class="space-y-2">
            <tr
              class="grid grid-cols-[repeat(6,_minmax(96px,_1fr)),_minmax(48px,_96px)] gap-x-2 rounded-md mb-10"
            >
              <td>
                <fwb-select v-model="filters.causer_id" :options="causers" />
              </td>
              <td>
                <fwb-select v-model="filters.action" :options="actionsPayload" />
              </td>
              <td>
                <fwb-select
                  style="margin-bottom: 5px"
                  :options="subjects"
                  v-model="filters.subject"
                />
                <fwb-input v-model="filters.subject_id" placeholder="ID of subject" />
              </td>
              <td>
                <VueDatePicker
                  placeholder="Date / Time*"
                  class="rounded-md"
                  :style="{
                    '--dp-input-padding': '10px 12px',
                    '--dp-border-color': '#343739'
                  }"
                  :dark="mode === 'dark'"
                  v-model="filters.date"
                  range
                  :partial-range="false"
                />
              </td>
              <td class="flex flex-row gap-2">
                <button
                  :disabled="requestTime"
                  @click="getAuditLogs"
                  type="submit"
                  style="margin-bottom: 10px; height: 40px"
                  class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5"
                >
                  <img
                    class="group-hover:scale-105 duration-300 mx-auto"
                    src="@/assets/images/magnifyingGlass.svg"
                    alt="magnifyingGlassIcon"
                  />
                </button>
                <button
                  style="height: 40px"
                  :disabled="requestTime"
                  @click="clearFilters"
                  class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5"
                >
                  <img
                    class="group-hover:scale-105 duration-300 mx-auto"
                    src="@/assets/images/crossedMagnifyingGlass.svg"
                    alt="crossedMagnifyingGlassIcon"
                  />
                </button>
              </td>
            </tr>

            <tr
              class="grid grid-cols-[repeat(6,_minmax(96px,_1fr)),_minmax(48px,_96px)] gap-x-2 rounded-md"
              v-for="(action, index) in actions"
              :key="index"
            >
              <td>
                <span v-if="action.causer?.email">{{
                  `${action.causer.email} (${action.causer_id})`
                }}</span>
              </td>
              <td>{{ action.event }}</td>
              <td>{{ action.subject_type + ` (${action.subject_id})` }}</td>
              <td class="text-ghost-gray">{{ formatTime(action.created_at) }}</td>
              <td>
                <fwb-button
                  style="background-color: rgb(95, 208, 82)"
                  class="border border-lush-green w-full h-full rounded-full bg-lush-green font-semibold text-midnight-ink"
                  @click="showModal(index)"
                >
                  Show
                </fwb-button>

                <fwb-modal :persistent="false" v-if="isShowModal" @close="closeModal">
                  <template #header>
                    <div class="flex items-center text-lg">Updated data</div>
                  </template>
                  <template #body>
                    <div v-html="diffs"></div>
                  </template>
                  <template #footer>
                    <fwb-button @click="closeModal" color="green"> Close </fwb-button>
                  </template>
                </fwb-modal>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <fwb-pagination
        v-if="actions.length !== 0"
        v-model="meta.current_page"
        :total-items="meta.total"
        :totalPages="meta.last_page"
        :perPage="meta.per_page"
        :showLabels="false"
        show-icons
        @click="paginationBarClicked"
      ></fwb-pagination>
    </section>
  </main>
</template>

<script setup>
import { inject, onBeforeMount, onMounted, ref } from 'vue'
import { useFetcher } from '@/compose/axios'
import { FwbButton, FwbInput, FwbModal, FwbPagination, FwbSelect } from 'flowbite-vue'
import moment from 'moment'
import VueDatePicker from '@vuepic/vue-datepicker'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@/stores/main'

const mainStore = useMainStore()
const { mode } = storeToRefs(mainStore)
const menuCollapsed = inject('menuCollapsed')

const isShowModal = ref(false)

const filters = ref({
  subject_id: null,
  causer_id: null,
  action: null,
  subject: null,
  date: null
})

const clearFilters = () => {
  filters.value = {
    causer_id: null,
    action: null,
    subject: null,
    date: null
  }

  getAuditLogs()
}

onMounted(() => loadFiltersData())

const causers = ref([])
const actionsPayload = ref([])
const subjects = ref([])

const loadFiltersData = () => {
  axiosInstance.get('/admin/audi-log/filters').then((response) => {
    const data = response.data

    causers.value = data.users
    actionsPayload.value = data.actions
    subjects.value = data.subjects
  })
}

function closeModal() {
  isShowModal.value = false
}

const diffs = ref('')

function showModal(index) {
  isShowModal.value = true

  let action = actions.value[index]

  let diff = formatChanges(action.properties.old, action.properties.attributes)

  diffs.value = !diff ? 'There is no changes' : diff
}

const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })

const actions = ref([])

const meta = ref({
  current_page: 1,
  from: null,
  last_page: null,
  path: null,
  per_page: null,
  to: null,
  total: null
})

const requestTime = ref(false)

const formatTime = (time) => {
  return moment(time).format('D.M.Y h:mm a')
}

const keyToTextValue = {
  area_name: 'Area Name',
  location: 'Location',
  area_short_name: 'Short Name',
  type: 'Type',
  enabled_cars: 'Cars',
  archived_at: 'Archived At',
  description: 'Description',
  discount_value: 'Discount Value',
  plus_minus: 'Plus/Minus',
  start_date: 'Start Date',
  end_date: 'End Date',
  priority: 'Priority',
  status: 'Status',
  unix_date: 'Unix Date'
}

const formatChanges = (oldValues, newValues) => {
  const mergedKeys = new Set([...Object.keys(oldValues || {}), ...Object.keys(newValues || {})])
  const changes = []

  for (let key of mergedKeys) {
    if (oldValues && newValues && oldValues[key] !== newValues[key]) {
      let textValue = keyToTextValue[key] || key

      let oldValue = oldValues?.[key]
      let newValue = newValues?.[key]
      let newLine = 'to'

      if (Array.isArray(newValue) || Array.isArray(oldValue)) {
        let oldTemp = []
        let newTemp = []
        oldValue.forEach((item) => {
          oldTemp.push(item.name)
        })
        newValue.forEach((item) => {
          newTemp.push(item.name)
        })

        oldValue = oldTemp.join(', ')
        newValue = newTemp.join(', ')

        oldValue = oldValue === '' ? 'Empty' : oldValue
        newValue = newValue === '' ? 'Empty' : newValue

        newLine = '<br> to'
      }

      oldValue = `<span style="color: #bb5c5c">${oldValue}</span>`
      newValue = `<span style="color: #5ba85b">${newValue}</span>`

      changes.push(`${textValue} from ${oldValue} ${newLine} ${newValue}`)
    }
  }

  if (changes.length === 0) {
    return false
  }

  return changes.join('<br>')
}

const paginationBarClicked = async () => {
  await getAuditLogs(meta.value.current_page)
}

onBeforeMount(() => getAuditLogs())

const formatDate = (date) => {
  let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
  let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date)
  let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)

  return `${year}-${month}-${day}`
}

const getAuditLogs = async () => {
  requestTime.value = true

  let url = '/admin/audit-log'

  url = url + `?page=${meta.value.current_page}`

  if (filters.value.action) {
    url = url + `&event=${filters.value.action}`
  }

  if (filters.value.causer_id) {
    url = url + `&causer_id=${filters.value.causer_id}`
  }

  if (filters.value.subject) {
    url = url + `&subject_type=${filters.value.subject}`
  }

  if (filters.value.subject_id) {
    url = url + `&subject_id=${filters.value.subject_id}`
  }

  if (filters.value.date) {
    const from = filters.value.date[0]
    const to = filters.value.date[1]

    console.log(formatDate(from), formatDate(to))

    url = url + `&from_date=${formatDate(from)}&to_date=${formatDate(to)}`
  }

  await axiosInstance.get(url).then((response) => {
    const audits = response.data

    actions.value = audits.data

    meta.value.current_page = audits.meta.current_page
    meta.value.from = audits.meta.from
    meta.value.last_page = audits.meta.last_page
    meta.value.per_page = audits.meta.per_page
    meta.value.to = audits.meta.to
    meta.value.total = audits.meta.total
    meta.value.path = audits.meta.path

    requestTime.value = false
  })
}
</script>
