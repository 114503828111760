<template>
  <main :class="menuCollapsed ? 'md:pl-[94px]' : 'md:pl-[238px]'" class="pl-6 pr-6 duration-300">
    <section class="flex justify-center items-center h-screen">
      <div class="flex items-center gap-x-2 text-lush-green text-5xl">
        <div>4</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-10 h-10 animate-spin"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
          />
        </svg>
        <div>4</div>
      </div>
    </section>
  </main>
</template>

<script setup>
import { inject } from 'vue'

const menuCollapsed = inject('menuCollapsed')
</script>
