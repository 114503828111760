import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useFetcher } from '@/compose/axios'

const STORE_NAME = 'cars'
const LOCALSTORAGE_KEY = 'cars'

export const useCarsStore = defineStore(STORE_NAME, () => {
  const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })

  const cars = ref(null)

  function $reset() {
    cars.value = null
    sessionStorage.removeItem(LOCALSTORAGE_KEY)
  }

  function update(data) {
    cars.value = data
    sessionStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(cars.value))
  }

  function loadData() {
    axiosInstance.get('/admin/cars').then((response) => {
      update(response.data.data)
    })
  }

  const loadDefaultValue = () => {
    const storageData = sessionStorage.getItem(LOCALSTORAGE_KEY)

    if (storageData) {
      update(JSON.parse(storageData))
    } else {
      loadData()
    }
  }

  loadDefaultValue()

  return { update, $reset, loadData, cars }
})
