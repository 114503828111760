<template>
  <main
    :class="menuCollapsed ? 'md:pl-[140px]' : 'md:pl-[284px]'"
    class="pl-6 pr-6 pt-[70px] pb-[140px] text-sm/[1.42] lg:text-lg/[1.38] duration-300"
  >
    <h1 class="flex items-end gap-x-6 mb-9 text-[40px] font-bold leading-[1.1] lg:text-[80px]">
      Areas
      <button
        v-if="checkPermission('areas.create')"
        @click="areaAdd"
        class="group rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel p-2.5 lg:mb-3.5"
      >
        <img
          class="group-hover:scale-105 duration-300 mx-auto"
          src="@/assets/images/plusSquare.svg"
          alt="copyIcon"
        />
      </button>
    </h1>
    <Form @submit="areasSubmit" class="form">
      <AreaModals @action="modalAction" />
      <section class="space-y-8">
        <div class="overflow-hidden" id="headerdiv">
          <table id="headertable" class="w-full">
            <thead>
              <tr
                class="grid grid-cols-[116px_repeat(6,_minmax(96px,_1fr)),_minmax(48px,_96px)] items-center gap-x-2 border-b border-silver-fog pb-1.5 text-ghost-gray"
              >
                <th @click="setOrder('id')" class="font-normal">
                  Prog Id
                  <span v-if="order.active_order === 'id'">
                    <sup v-if="order.sort === 'asc'">↑</sup>
                    <sup v-if="order.sort === 'desc'">↓</sup>
                  </span>
                </th>
                <th @click="setOrder('area_name')" class="font-normal">
                  Area Name
                  <span v-if="order.active_order === 'area_name'">
                    <sup v-if="order.sort === 'asc'">↑</sup>
                    <sup v-if="order.sort === 'desc'">↓</sup>
                  </span>
                </th>
                <th @click="setOrder('location')" class="font-normal">
                  Location
                  <span v-if="order.active_order === 'location'">
                    <sup v-if="order.sort === 'asc'">↑</sup>
                    <sup v-if="order.sort === 'desc'">↓</sup>
                  </span>
                </th>
                <th @click="setOrder('area_short_name')" class="font-normal">
                  Short Name
                  <span v-if="order.active_order === 'area_short_name'">
                    <sup v-if="order.sort === 'asc'">↑</sup>
                    <sup v-if="order.sort === 'desc'">↓</sup>
                  </span>
                </th>
                <th @click="setOrder('type')" class="font-normal col-span-2">
                  Type
                  <span v-if="order.active_order === 'type'">
                    <sup v-if="order.sort === 'asc'">↑</sup>
                    <sup v-if="order.sort === 'desc'">↓</sup>
                  </span>
                </th>

                <th class="font-normal">Cars</th>
              </tr>
            </thead>
          </table>
        </div>
        <!-- Adapt table for mobile devices -->
        <div
          class="overflow-y-scroll max-h-[100vh]"
          onscroll="document.getElementById('headerdiv').scrollLeft = this.scrollLeft;"
        >
          <table id="bodytable" class="grid w-full gap-y-4 text-center">
            <tbody id="fix" class="space-y-2 scroll-smooth">
              <!-- filters -->
              <tr
                class="grid grid-cols-[116px_repeat(6,_minmax(96px,_1fr)),_minmax(48px,_96px)] gap-x-2 rounded-md'"
              >
                <td>
                  <input
                    v-model="filters.prog_id.value"
                    class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                    placeholder="Prog Id"
                    type="text"
                  />
                </td>
                <td>
                  <input
                    v-model="filters.area_name.value"
                    class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                    placeholder="Area name"
                    type="text"
                  />
                </td>
                <td>
                  <input
                    v-model="filters.location.value"
                    class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                    placeholder="Location"
                    type="text"
                  />
                </td>
                <td>
                  <input
                    v-model="filters.area_short_name.value"
                    class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                    placeholder="Short name"
                    type="text"
                  />
                </td>
                <td class="col-span-2 flex flex-row gap-3">
                  <div
                    @click="filters.type.value = 'polygon'"
                    :class="{
                      'w-1/2 dark:text-white cursor-pointer font-medium rounded-lg text-sm px-4 py-2 h-full flex items-center justify-center': true,
                      'dark:bg-gray-800 dark:focus:ring-gray-700 dark:border-gray-700 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 text-black focus:outline-none focus:ring-4 focus:ring-gray-300':
                        filters.type.value !== 'polygon',
                      'bg-green-200 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:focus:ring-green-800 hover:bg-green-300 dark:hover:bg-green-700':
                        filters.type.value === 'polygon'
                    }"
                  >
                    <!---->
                    <span> Polygon </span>
                    <!---->
                  </div>
                  <div
                    @click="filters.type.value = 'radius'"
                    :class="{
                      'w-1/2 dark:text-white cursor-pointer font-medium rounded-lg text-sm px-4 py-2 h-full flex items-center justify-center': true,
                      'dark:bg-gray-800 dark:focus:ring-gray-700 dark:border-gray-700 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 text-black focus:outline-none focus:ring-4 focus:ring-gray-300':
                        filters.type.value !== 'radius',
                      'bg-green-200 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:focus:ring-green-800 hover:bg-green-300 dark:hover:bg-green-700':
                        filters.type.value === 'radius'
                    }"
                  >
                    <!---->
                    <span> Radius </span>
                    <!---->
                  </div>
                </td>
                <td>
                  <div class="multiselect relative uppercase">
                    <div
                      @click="showMultiselectOptions"
                      class="multiselectHeading cursor-pointer overflow-x-clip whitespace-nowrap rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 pl-3 pr-9"
                    >
                      <!-- <span>{{ selectedCars(filters.cars.cars_array) }}</span>  -->
                      <!-- Вывод выбранных машин-->
                      <span>Cars</span>
                      <div
                        class="absolute right-0 top-[calc(50%-12px)] my-auto bg-[#eaeaeb] dark:bg-shadowy-steel px-3"
                      >
                        <img src="@/assets/images/arrow.svg" alt="arrowIcon" />
                      </div>
                    </div>
                    <div
                      class="options absolute left-0 top-[calc(100%_+_12px)] z-10 w-max min-w-full divide-y divide-midnight-ink rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel hidden"
                    >
                      <label
                        :key="index"
                        v-for="(car, index) in cars"
                        class="flex cursor-pointer items-center gap-x-3 p-3"
                      >
                        <input
                          :id="'filter-area-car' + car.class_id"
                          v-model="filters.cars.cars_array"
                          v-bind:value="car"
                          class="h-6 w-6 cursor-pointer appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat checked:bg-[url(@/assets/images/tick.svg)] checked:!bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                          type="checkbox"
                        />
                        <span>{{ car.class_name }}</span>
                      </label>
                    </div>
                  </div>
                </td>
                <td class="flex flex-row gap-2">
                  <button
                    type="button"
                    @click="filterAreas"
                    class="group w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5"
                  >
                    <img
                      class="group-hover:scale-105 duration-300 mx-auto"
                      src="@/assets/images/magnifyingGlass.svg"
                      alt="magnifyingGlassIcon"
                    />
                  </button>
                  <button
                    type="button"
                    @click="resetFilterAreas"
                    class="group w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5"
                  >
                    <img
                      class="group-hover:scale-105 duration-300 mx-auto"
                      src="@/assets/images/crossedMagnifyingGlass.svg"
                      alt="crossedMagnifyingGlassIcon"
                    />
                  </button>
                </td>
              </tr>
              <!-- end filters -->

              <tr
                v-for="area in areas"
                :key="area.id"
                :class="{
                  'grid grid-cols-[116px_repeat(6,_minmax(96px,_1fr)),_minmax(48px,_96px)] gap-x-2 rounded-md': true,
                  'bg-lush-green': area.just_added
                }"
              >
                <td
                  :class="
                    area.status === 0
                      ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                      : ''
                  "
                  class="self-center"
                >
                  <span></span>
                  <p class="self-center" :class="{ 'text-orange-500': area.status === 0 }">
                    {{ area.id }}
                  </p>
                </td>
                <td>
                  <Field
                    :value="area.area_name"
                    :name="'area[' + area.id + '].area_name'"
                    v-slot="{ field }"
                  >
                    <input
                      v-bind="field"
                      :value="area.area_name"
                      :disabled="requestTime || !checkPermission('areas.edit')"
                      class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
                      v-model="area.area_name"
                    />
                  </Field>
                </td>
                <td>
                  <vue-google-autocomplete
                    @placechanged="getAddressData"
                    @focus="focusInput($event, area.id)"
                    @beforeinput="beforeInput($event, area.id)"
                    classname="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                    :name="'area[' + area.id + '].location'"
                    placeholder="Location placeholder"
                    :id="'area-' + area.id + '-location'"
                    maxlength="230"
                    :auto-complete-string="area.location"
                    types=""
                    :disabled="requestTime || !checkPermission('areas.edit')"
                  >
                  </vue-google-autocomplete>
                </td>
                <td>
                  <Field
                    :value="area.area_short_name"
                    :name="'area[' + area.id + '].area_short_name'"
                    v-slot="{ field }"
                  >
                    <input
                      v-bind="field"
                      :value="area.area_short_name"
                      :disabled="requestTime || !checkPermission('areas.edit')"
                      class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
                      v-model="area.area_short_name"
                    />
                  </Field>
                </td>
                <td>
                  <div
                    @click="showModal('setPolygon', area)"
                    :class="{
                      'dark:text-white cursor-pointer font-medium rounded-lg text-sm px-4 py-2 h-full flex items-center justify-center': true,
                      'dark:bg-gray-800 dark:focus:ring-gray-700 dark:border-gray-700 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 text-black focus:outline-none focus:ring-4 focus:ring-gray-300':
                        area.type === 0,
                      'bg-green-200 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:focus:ring-green-800 hover:bg-green-300 dark:hover:bg-green-700':
                        area.type === 1
                    }"
                  >
                    <!---->
                    <span class=""> Polygon </span>
                    <!---->
                  </div>
                </td>
                <td>
                  <div
                    @click="showModal('setRadius', area)"
                    :class="{
                      'dark:text-white cursor-pointer font-medium rounded-lg text-sm px-4 py-2 h-full flex items-center justify-center': true,
                      'bg-green-200 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:focus:ring-green-800 hover:bg-green-300 dark:hover:bg-green-700':
                        area.type === 0,
                      'dark:bg-gray-800 dark:focus:ring-gray-700 dark:border-gray-700 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 text-black focus:outline-none focus:ring-4 focus:ring-gray-300':
                        area.type === 1
                    }"
                  >
                    <!---->
                    <span class=""> Radius </span>
                    <!---->
                  </div>
                </td>
                <td class="multiselect relative">
                  <button
                    @click="openAreaCars(area)"
                    class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel p-2.5 pr-7 text-left bg-[url(@/assets/images/arrow.svg)] bg-no-repeat bg-[center_right_10px]"
                    type="button"
                  >
                    {{ selectedCars(area.enabled_cars) }}
                  </button>

                  <!-- Dropdown menu -->
                  <div
                    :class="{
                      'options absolute left-0 top-[calc(100%_+_12px)] z-10 w-max min-w-full divide-y divide-midnight-ink shadown rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel': true,
                      hidden: !area.opened
                    }"
                  >
                    <Field :name="'area[' + area.id + '].enabled_cars'" v-slot="{ field }">
                      <label
                        :key="index"
                        v-for="(car, index) in cars"
                        class="flex cursor-pointer items-center gap-x-3 p-3"
                      >
                        <input
                          @change="changeEnabled(car, area)"
                          :checked="carChecked(area.enabled_cars, car.class_id)"
                          v-bind="field"
                          class="h-6 w-6 cursor-pointer appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat checked:bg-[url(@/assets/images/tick.svg)] checked:!bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                          type="checkbox"
                        />
                        <span>{{ car.class_name }}</span>
                      </label>
                    </Field>
                  </div>
                </td>
                <td>
                  <div
                    v-if="checkPermission('areas.delete')"
                    @click="showModal('areaDelete', area)"
                    class="group w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 cursor-pointer"
                  >
                    <img
                      class="group-hover:scale-105 duration-300 mx-auto"
                      src="@/assets/images/trash.svg"
                      alt="trashIcon"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <button
          type="submit"
          v-if="!requestTime && checkPermission('areas.edit')"
          class="w-full rounded-full border border-lush-green bg-lush-green py-[22px] font-semibold leading-[1.1] text-midnight-ink duration-300 disabled:bg-transparent dark:disabled:text-white hover:bg-transparent dark:hover:text-white"
        >
          Update
        </button>

        <button
          disabled
          v-if="requestTime"
          class="w-full rounded-full border py-[22px] font-semibold leading-[1.1] duration-300 disabled:bg-transparent disabled:text-white bg-transparent text-white"
        >
          <LoadSpinner />
          Updating...
        </button>

        <fwb-pagination
          v-model="meta.current_page"
          :total-items="meta.total"
          :totalPages="meta.last_page"
          :perPage="meta.per_page"
          :showLabels="false"
          show-icons
          @click="paginationBarClicked"
        ></fwb-pagination>
      </section>
    </Form>
  </main>
</template>

<script setup>
import { inject, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { Field, Form } from 'vee-validate'
import { collect } from 'collect.js'
import { useCarsStore } from '@/stores/cars'
import { useAreasStore } from '@/stores/areas'
import { useGoogleAddressArea } from '@/compose/googleAddressArea'
import { useFetcher } from '@/compose/axios'
import { useUserStore } from '@/stores/user'
import { useModalStore } from '@/stores/modal'

import LoadSpinner from '@/components/custom/LoadSpinner.vue'
import VueGoogleAutocomplete from '@/components/custom/VueGoogleAutocomplete.vue'
import AreaModals from '@/components/modals/AreaModals.vue'
import { FwbPagination } from 'flowbite-vue'

const carsStore = useCarsStore()
const areasStore = useAreasStore()
const userStore = useUserStore()
const modalStore = useModalStore()

const { cars } = storeToRefs(carsStore)
const { areas } = storeToRefs(areasStore)
const { modalType, modalData } = storeToRefs(modalStore)

const { loadFields, beforeInput, focusInput, getAddressData } = useGoogleAddressArea()

const order = ref({
  sort: 'asc',
  active_order: 'area_name'
})

const filters = ref({
  prog_id: {
    value: null
  },
  area_name: {
    value: null
  },
  location: {
    value: null
  },
  area_short_name: {
    value: null
  },
  type: {
    value: null
  },
  cars: {
    cars_array: []
  }
})

const meta = ref({
  current_page: 1,
  from: null,
  last_page: null,
  path: null,
  per_page: null,
  to: null,
  total: null
})

const resetFilterAreas = () => {
  let filtersDefaultValue = {
    prog_id: {
      value: null
    },
    area_name: {
      value: null
    },
    location: {
      value: null
    },
    area_short_name: {
      value: null
    },
    type: {
      value: null
    },
    cars: {
      cars_array: []
    }
  }

  filters.value = { ...filtersDefaultValue }
  areasStore.loadData()
}

const showModal = (type, entity) => {
  modalStore.showModal(type, entity)
}
const closeModal = () => {
  modalStore.closeModal()
}

const modalAction = () => {
  if (modalType.value === 'areaDelete') {
    areaDelete()
  }

  if (modalType.value === 'setRadius') {
    modalData.value.type = 0
    areaUpdate()
  }

  if (modalType.value === 'setPolygon') {
    modalData.value.type = 1
    areaUpdate()
  }

  closeModal()
}
const checkPermission = (permission) => {
  return userStore.checkPermission(permission)
}

const showMultiselectOptions = (event) => {
  const currentMultiselectOptions = event.currentTarget.nextElementSibling

  document.querySelectorAll('.multiselect .options').forEach((multiselectOptions) => {
    if (multiselectOptions !== currentMultiselectOptions) multiselectOptions.classList.add('hidden')
  })

  currentMultiselectOptions.classList.toggle('hidden')
}

const carChecked = (cars, carId) => {
  const tempArray = cars.split(', ')
  return tempArray.includes(carId.toString())
}
const selectedCars = (area_cars) => {
  const tempObject = []
  const splitOject = area_cars.split(', ')

  if (splitOject.length) {
    splitOject.forEach((e) => {
      let carTemp = collect(cars.value).where('class_id', Number(e)).first()
      if (carTemp) {
        tempObject.push(carTemp.short_name)
      }
    })
  }

  if (tempObject.length === cars.value.length) {
    return 'All'
  }

  return tempObject.join(', ')
}

const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
const menuCollapsed = inject('menuCollapsed')

const requestTime = ref(true)

const openAreaCars = (area) => {
  if (!checkPermission('areas.edit')) {
    return false
  }
  areas.value.forEach((e) => (area.id !== e.id ? (e.opened = false) : ''))
  area['opened'] = !area['opened']
}

const changeEnabled = (car, area) => {
  if (carChecked(area.enabled_cars, car.class_id.toString())) {
    carUncheck(car, area)
  } else {
    carCheck(car, area)
  }
}

const carCheck = (car, area) => {
  const tempArray = area.enabled_cars.split(', ')
  tempArray.push(car.class_id)
  area.enabled_cars = tempArray.join(', ')
}

const carUncheck = (car, area) => {
  const tempArray = area.enabled_cars.split(', ')
  const newArray = tempArray.filter((e) => e !== car.class_id.toString())
  area.enabled_cars = newArray.join(', ')
}

const areaUpdate = () => {
  let index = areas.value.findIndex((a) => a.id === modalData.value.id)
  console.log(modalData.value)
  areas.value[index] = modalData.value
}
const areaAdd = () => {
  requestTime.value = true
  axiosInstance
    .post('/admin/areas/create', {
      radius: 1,
      location: '',
      area_short_name: '',
      enabled_cars: '1'
    })
    .then((response) => {
      areas.value.unshift(response.data.data)

      areasStore.update(areas.value)
      loadFields(areas.value)
      areas.value[0].just_added = 1

      requestTime.value = false
    })
}

const areaDelete = () => {
  requestTime.value = true
  axiosInstance.post('/admin/areas/delete/' + modalData.value.id).then((response) => {
    if (response.data.status === 'success') {
      areas.value = areas.value.filter((e) => e.id !== modalData.value.id)
      areasStore.update(areas.value)
      requestTime.value = false
    }
  })
}

const areasSubmit = async () => {
  requestTime.value = true
  //todo: update store values therefore values
  await axiosInstance.post('/admin/areas', areas.value).then(() => {
    filterAreas()
  })
}

const filterAreas = async () => {
  requestTime.value = true
  // loadText.value = 'Filtering Areas...'
  let filterData = { filters: filters.value, order: order.value }
  filterData.page = meta.value.current_page
  filterData.per_page = 10

  axiosInstance
    .post('/admin/areas/filter', filterData)
    .then((response) => {
      const data = response.data
      areasStore.update(response.data.data)

      meta.value.current_page = data.meta.current_page
      meta.value.from = data.meta.from
      meta.value.last_page = data.meta.last_page
      meta.value.per_page = data.meta.per_page
      meta.value.to = data.meta.to
      meta.value.total = data.meta.total
      meta.value.path = data.meta.path

      requestTime.value = false
    })
    .catch((response) => {
      console.log(response)
      requestTime.value = false
    })
}

const setOrder = (column) => {
  order.value['sort'] = order.value['sort'] === 'asc' ? 'desc' : 'asc'
  order.value['active_order'] = column

  filterAreas()
}

const paginationBarClicked = async () => {
  await filterAreas()
}

onBeforeMount(async () => {
  await filterAreas()
})
</script>

<style scoped></style>
