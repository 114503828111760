<template>
  <div>
    <div id="polygon-label-map" class="map-margins"></div>
  </div>
</template>

<script setup>
import { useModalStore } from '@/stores/modal'
import loadGoogleMapsApi from 'load-google-maps-api'
import { storeToRefs } from 'pinia'
import { onBeforeMount, onMounted, ref } from 'vue'

import { useFetcher } from '@/compose/axios'

const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
const modalStore = useModalStore()
const { modalData } = storeToRefs(modalStore)

const map = ref(null)
const polygonCoords = ref([
  { lat: 38.11503413260137, lng: 12.139101562499999 },
  { lat: 42.4738598804145, lng: 13.605033203125009 },
  { lat: 40.517605786441074, lng: 19.155503906249994 }
])
const polygonShape = ref(null)
const areasExistsPolygonShape = ref(null)

// const loadDrawingTools = () => {
//   const { google } = window
//   const myDrawingManager = new google.maps.drawing.DrawingManager({
//     drawingMode: null,
//     drawingControl: true,
//     drawingControlOptions: {
//       position: google.maps.ControlPosition.TOP_LEFT,
//       drawingModes: [google.maps.drawing.OverlayType.POLYGON]
//     },
//     polygonOptions: {
//       draggable: true,
//       editable: true,
//       fillColor: '#cccccc',
//       fillOpacity: 0.5,
//       strokeColor: '#000000'
//     }
//   })
//   myDrawingManager.setMap(map.value)
// }
const updatePolygonCoordsState = () => {
  const allPolygonCoords = polygonShape.value.getPath()
  const coordsToSet = []

  for (let i = 0; i < allPolygonCoords.length; i++) {
    const data = {
      lat: allPolygonCoords.getAt(i).lat(),
      lng: allPolygonCoords.getAt(i).lng()
    }
    data && coordsToSet.push(data)
  }
  polygonCoords.value = coordsToSet
  modalData.value.polygon = coordsToSet
}

onMounted(() => {
  loadGoogleMapsApi({
    key: import.meta.env.VITE_APP_GOOGLE_API_KEY,
    libraries: ['places', 'drawing', 'geometry', 'marker']
  }).then(async () => {
    axiosInstance.get('/admin/areas/polygons').then(async (response) => {
      const mapZoom = 5
      const { google } = window
      const mapOptions = {
        zoom: mapZoom,
        mapId: '45',
        mapTypeId: google.maps.MapTypeId.HYBRID,
        center: new google.maps.LatLng({ lat: 42.4738598804145, lng: 13.605033203125009 }),
        mapTypeControl: true,
        streetViewControl: false,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_LEFT
        }
      }
      map.value = new google.maps.Map(document.getElementById('polygon-label-map'), mapOptions)

      const mainPolygonBounds = new google.maps.LatLngBounds()

      for (let j = 0; j < polygonCoords.value.length; j++) {
        const x = {
          lat: polygonCoords.value[j].lat,
          lng: polygonCoords.value[j].lng
        }
        const BoundLatLng = new google.maps.LatLng({
          lat: parseFloat(x.lat),
          lng: parseFloat(x.lng)
        })
        mainPolygonBounds.extend(BoundLatLng)
      }
      const centroid = mainPolygonBounds.getCenter()

      response.data.data.forEach((area) => {
        // Polygon
        if (area.polygon) {
          const supportPolygonBounds = new google.maps.LatLngBounds()

          for (let j = 0; j < area.polygon.length; j++) {
            const x = {
              lat: area.polygon[j].lat,
              lng: area.polygon[j].lng
            }
            const BoundLatLng = new google.maps.LatLng({
              lat: parseFloat(x.lat),
              lng: parseFloat(x.lng)
            })
            supportPolygonBounds.extend(BoundLatLng)
          }
          const supportPolygonCenter = supportPolygonBounds.getCenter()

          areasExistsPolygonShape.value = new google.maps.Polygon({
            paths: area.polygon,
            strokeColor: '#86d164',
            map: map.value,
            strokeWeight: 2,
            fillColor: '#b64277',
            fillOpacity: 0.3,
            zIndex: 99998,
            editable: false,
            draggable: false
          })

          areasExistsPolygonShape.value.setMap(map.value)

          let mapLabel = new google.maps.Marker({
            position: new google.maps.LatLng(
              supportPolygonCenter.lat(),
              supportPolygonCenter.lng()
            ),
            label: area.area_short_name.toString().toUpperCase(),
            zIndex: 101,
            map: map.value
          })
          console.log(mapLabel)
        }

        if (area.polygon === null && area.latitude && area.longitude) {
          console.log('Drawing circle for area:', area)
          const center = new google.maps.LatLng({
            lat: parseFloat(area.latitude),
            lng: parseFloat(area.longitude)
          })
          let circle = new google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#0000FF',
            fillOpacity: 0.35,
            map: map.value,
            center: center,
            radius: area.radius * 1000
          })
          circle.setMap(map.value)

          let outerCircle = new google.maps.Circle({})
          outerCircle.setMap(map.value)
          let mapLabel = new google.maps.Marker({
            position: center,
            label: area.area_short_name.toString().toUpperCase(),
            zIndex: 101,
            map: map.value
          })
          console.log(mapLabel)
        }
      })

      polygonShape.value = new google.maps.Polygon({
        paths: modalData.value.polygon ?? polygonCoords.value,
        strokeColor: '#ffffff',
        map: map.value,
        strokeWeight: 3,
        fillColor: '#7b00ff',
        fillOpacity: 0.6,
        zIndex: 99999,
        editable: true,
        draggable: true
      })

      polygonShape.value.setMap(map.value)
      map.value.setCenter(centroid)
      map.value.setZoom(5)
      //this.loadDrawingTools();

      polygonShape.value.getPaths().forEach((path) => {
        google.maps.event.addListener(path, 'insert_at', () => {
          updatePolygonCoordsState()
        })

        google.maps.event.addListener(path, 'remove_at', () => {
          updatePolygonCoordsState()
        })

        google.maps.event.addListener(path, 'set_at', () => {
          updatePolygonCoordsState()
        })
      })
    })
  })
})

onBeforeMount(() => {
  // axiosInstance.get('/admin/areas/polygons').then((response) => {
  //   response.data.data.forEach((area) => {
  //     areasPolygons.value.push(area.polygon)
  //   })
  // })
})
</script>

<style scoped>
.map-margins {
  height: 500px;
  width: 500px;
  margin: 20px 30px;
}
</style>
