import axios from 'axios'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'

export default {
  install: (app, options) => {
    const userStore = useUserStore()
    const { isLoggedIn, token } = storeToRefs(userStore)

    let headersData = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    if (isLoggedIn.value) {
      headersData['Authorization'] = `Bearer ${token.value}`
    }

    axios.defaults.headers.common = headersData

    let axiosInstance = axios.create({
      baseURL: options.baseUrl
    })

    app.config.globalProperties.$axios = axiosInstance
    app.provide('axios', axiosInstance)
  }
}
