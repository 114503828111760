import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import router from '@/router'
import axios from 'axios'
import { collect } from 'collect.js'

export const useUserStore = defineStore('user', () => {
  const user = ref(null)
  const requestTime = ref(false)
  const loginErrors = ref({})
  const token = computed(() => user.value?.token)
  const permissions = computed(() => collect(user.value.permissions).pluck('name'))
  const isLoggedIn = computed(() => !!user.value?.id)
  const fullName = computed(() => {
    return isLoggedIn.value ? user.value.name + ' ' + user.value.last_name : false
  })

  function fill(data) {
    user.value = data
    localStorage.setItem('user', JSON.stringify(data))
  }

  function update(data) {
    user.value = { ...user.value, ...data }
    localStorage.setItem('user', JSON.stringify(user.value))
  }

  function preventLogout() {
    user.value = null
    localStorage.removeItem('user')

    location.reload()
  }

  const fillData = () => {
    const userStorage = localStorage.getItem('user')
    if (userStorage) {
      return fill(JSON.parse(userStorage))
    }
  }

  fillData()

  function checkPermission(item) {
    return permissions.value.contains(item)
  }

  const logIn = async (loginData) => {
    requestTime.value = true
    let headersData = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    if (isLoggedIn.value) {
      headersData['Authorization'] = `Bearer ${token.value}`
    }

    axios.defaults.headers.common = headersData

    let axiosInstance = axios.create({
      baseURL: import.meta.env.VITE_APP_API_URL
    })
    await axiosInstance
      .post('/admin/login', loginData)
      .then(function (response) {
        if (response.data.status) {
          requestTime.value = false
          fill(response.data.data)
          router.push({ name: 'home' })
        }
      })
      .catch((response) => {
        requestTime.value = false
        loginErrors.value = { message: 'Incorrect login or password' }
        console.log(response.response)
      })
  }

  return {
    token,
    user,
    fullName,
    isLoggedIn,
    requestTime,
    permissions,
    loginErrors,
    fill,
    update,
    logIn,
    checkPermission,
    preventLogout
  }
})
