import { defineStore } from 'pinia'
import { inject, ref } from 'vue'

const STORE_NAME = 'calc'
const LOCALSTORAGE_KEY = 'calc'
const LOCALSTORAGE_KEY_CALC = 'calcData'
export const useCalcStore = defineStore(STORE_NAME, () => {
  const axios = inject('axios')
  const defaultData = {
    pickup: null,
    dropoff: null,
    type_of_service: 'oneWayTransfer',
    date_start: null,
    distance: null,
    extra_kms: null,
    hours: null
  }

  const calc = ref(null)
  const calcData = ref(null)
  function $reset() {
    calc.value = defaultData
    calcData.value = null
    sessionStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(defaultData))
    sessionStorage.removeItem(LOCALSTORAGE_KEY_CALC)
  }

  function update(data) {
    calc.value = { ...calc.value, ...data }
    sessionStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(calc.value))
  }

  function updateCalcData(data) {
    calcData.value = data
    sessionStorage.setItem(LOCALSTORAGE_KEY_CALC, JSON.stringify(calcData.value))
  }

  async function loadData() {
    const url =
      calc.value.type_of_service === 'oneWayTransfer'
        ? 'dashboard/price/cars'
        : 'dashboard/price/cars/hourly'

    await axios.post(url, calc.value).then((response) => {
      updateCalcData(response.data.data.prices)
      update({ distance: response.data.data.distance })
    })
  }

  const loadDefaultValue = () => {
    const storageData = sessionStorage.getItem(LOCALSTORAGE_KEY)
    if (storageData) {
      update(JSON.parse(storageData))
    } else {
      update(defaultData)
    }
  }

  const loadDefaultValueCalc = () => {
    const storageData = sessionStorage.getItem(LOCALSTORAGE_KEY_CALC)
    if (storageData) {
      updateCalcData(JSON.parse(storageData))
    }
  }

  loadDefaultValue()
  loadDefaultValueCalc()
  return { update, $reset, loadData, calc, calcData }
})
