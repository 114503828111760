import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useGoogleAddressArea } from '@/compose/googleAddressArea'
import { useFetcher } from '@/compose/axios'

const STORE_NAME = 'areas'
const LOCALSTORAGE_KEY = 'areas'

export const useAreasStore = defineStore(STORE_NAME, () => {
  const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
  const { loadFields } = useGoogleAddressArea()
  const areas = ref(null)

  function $reset() {
    areas.value = null
    loadFields(null)
    sessionStorage.removeItem(LOCALSTORAGE_KEY)
  }

  function updateAreaLocation(id, addressData, autoCompleteText) {
    const findKey = Object.keys(areas.value).find((key) => areas.value[key].id === id.value)

    areas.value[findKey].location = autoCompleteText
    areas.value[findKey].latitude = addressData.latitude
    areas.value[findKey].longitude = addressData.longitude
  }

  function update(data) {
    areas.value = data
    loadFields(data)
    sessionStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(areas.value))
  }

  function loadData() {
    axiosInstance.get('/admin/areas').then((response) => {
      update(response.data.data)
      loadFields(response.data.data)
    })
  }

  const loadDefaultValue = () => {
    const storageData = sessionStorage.getItem(LOCALSTORAGE_KEY)

    if (storageData) {
      update(JSON.parse(storageData))
    } else {
      loadData()
    }
  }

  loadDefaultValue()

  return { update, $reset, loadData, updateAreaLocation, areas }
})
