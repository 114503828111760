import { defineStore } from 'pinia'
import { ref } from 'vue'

const STORE_NAME = 'modal'
const LOCALSTORAGE_KEY = 'modal'

export const useModalStore = defineStore(STORE_NAME, () => {
  const isShowModal = ref(false)

  const modalType = ref(null)
  const modalData = ref(null)

  function showModal(type, entity) {
    isShowModal.value = true
    modalType.value = type
    modalData.value = entity
  }

  function closeModal() {
    isShowModal.value = false
  }

  function $reset() {
    modalType.value = null
    modalData.value = null
    sessionStorage.removeItem(LOCALSTORAGE_KEY)
  }

  return { showModal, closeModal, $reset, modalType, modalData, isShowModal }
})
