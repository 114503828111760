import { ref } from 'vue'
import { useAreasStore } from '@/stores/areas'

export function useGoogleAddressArea() {
  const fields = ref({})
  const activeId = ref(null)
  const areaStore = useAreasStore()
  const loadFields = (areas) => {
    if (areas === null) {
      fields.value = {}
      return null
    }

    areas.forEach((e) => {
      fields[e.id] = {
        name: ['area', e.id, 'location'].join('-'),
        //valid: false,
        value: e.location
      }
    })
  }

  const isLatin = (event) =>
    /^[a-zA-Z0-9?><;,{}[\]\-_+=!@#$%\\^&*|'\s]*$/.test(event.data) ? true : event.preventDefault()

  const beforeInput = (event, fieldName) => {
    isLatin(event)
    activeId.value = fieldName
  }

  const focusInput = (event, fieldName) => {
    activeId.value = fieldName
  }

  const getAddressData = (addressData, placeResultData, id, autocompleteText) => {
    areaStore.updateAreaLocation(activeId, addressData, autocompleteText)
  }

  const noResultsFound = (/*evt, data*/) => {
    //TODO: Make noResultFound
  }

  // expose managed state as return value
  return {
    loadFields,
    noResultsFound,
    fields,
    beforeInput,
    focusInput,
    getAddressData
  }
}
