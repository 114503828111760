import { reactive, ref } from 'vue'

const getFieldsWay = (type) => {
  switch (type) {
    //For the summary fields are valid for picked in first step
    case 'summary': {
      return {
        pickup: {
          name: 'pickup',
          placeholder: 'Pick Up Location*',
          valid: true
        },
        dropoff: {
          name: 'dropoff',
          placeholder: 'Drop off Location*',
          valid: true
        }
      }
    }
    //One way transfer has twofields
    case 'oneWayTransfer': {
      return {
        pickup: {
          name: 'pickup',
          placeholder: 'Pick Up Location*',
          valid: false
        },
        dropoff: {
          name: 'dropoff',
          placeholder: 'Drop off Location*',
          valid: false
        }
      }
    }
    //Other types of transfer
    default: {
      return {
        pickup: {
          name: 'pickup',
          placeholder: 'Pick Up Location*',
          valid: false
        }
      }
    }
  }
}

export function useGoogleAddress(type = 'oneWayTransfer') {
  const dataOfFields = getFieldsWay(type)

  const dropoff = ref(null)
  const pickup = ref(null)

  const activeAuto = ref('')

  const fields = reactive(dataOfFields)

  const isLatin = (event) =>
    /^[a-zA-Z0-9?><;,{}[\]\-_+=!@#$%\\^&*|'\s]*$/.test(event.data) ? true : event.preventDefault()

  const beforeInput = (event, fieldName) => {
    if (event.inputType !== 'insertFromPaste') {
      isLatin(event)
    }
    activeAuto.value = fieldName
    fields[fieldName].valid = false
  }

  const focusInput = (event, fieldName) => {
    activeAuto.value = fieldName
    fields[fieldName].valid = false
  }

  const getAddressData = (addressData /*placeResultData, id, autocompleteText*/) => {
    const countries =
      'Austria, Belgium, Bulgaria, Greece, Denmark, Ireland, Spain, Italy, Cyprus, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Finland, France, Croatia, Czechia, Sweden, Estonia, Germany, Hungary, Switzerland'
    const countryArray = countries.split(', ')

    const countryChecked = addressData.country
    if (countryArray.includes(countryChecked)) {
      fields[activeAuto.value].valid = true
    }
  }

  const noResultsFound = (/*evt, data*/) => {
    //TODO: Make noResultFound
  }

  // expose managed state as return value
  return {
    noResultsFound,
    fields,
    beforeInput,
    focusInput,
    pickup,
    dropoff,
    getAddressData
  }
}
