<template>
  <Form @submit="hourlySubmit" class="form" :validation-schema="hourlySchema" v-slot="{ errors }">
    <div
      class="mb-8 grid gap-4 text-lg/[1.38] md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-[repeat(2,_29.6795%),_22.9108%,_14.3889%]"
    >
      <Field :name="fields.pickup.name" v-slot="{ field }">
        <vue-google-autocomplete
          @placechanged="getAddressData"
          @focus="focusInput($event, 'pickup')"
          @beforeinput="beforeInput($event, 'pickup')"
          classname="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
          :class="errors.pickup ? 'error' : ''"
          v-bind="field"
          :placeholder="fields.pickup.placeholder"
          types=""
          id="home_form_2_pickup"
          maxlength="230"
        >
        </vue-google-autocomplete>
      </Field>

      <Field
        as="select"
        class="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
        :class="errors.hours ? 'error' : ''"
        name="hours"
        v-model="hourly"
      >
        <option
          v-for="value in [2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :key="value"
          :value="value"
          :selected="value === 2"
        >
          {{ value }} hours
        </option>
      </Field>
      <Field name="date_start" v-slot="{ field }">
        <VueDatePicker
          class="input"
          placeholder="Date / Time*"
          :class="errors.date_start ? 'error' : ''"
          :preview-format="dateFormat"
          :clearable="false"
          :format="dateFormat"
          v-bind="field"
          v-model="datePicker"
          @internal-model-change="handleInternal"
          :min-date="minDate"
          :min-time="minTime"
        >
          <template v-slot:right-sidebar>
            <div class="overflow-y-scroll px-3 py-2" style="max-height: 300px">
              <div
                v-for="time in timeOptions"
                :key="time"
                @click="setTime(time)"
                :class="[
                  'time-option cursor-pointer',
                  time === selectedTime ? 'time-selected' : ''
                ]"
              >
                {{ time }}
              </div>
            </div>
          </template>
        </VueDatePicker>
      </Field>
      <Field name="extra_kms" v-slot="{ field }">
        <input
          name="extra_kms"
          v-bind="field"
          v-model="extra_kms"
          class="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green border border-[#6B7280] focus:border-transparent text-base"
          placeholder="Extra Kms"
        />
      </Field>
    </div>
    <button
      class="w-full rounded-full border border-lush-green bg-lush-green py-[22px] text-lg/[1.1] font-semibold text-midnight-ink duration-300 hover:bg-transparent dark:hover:text-white"
    >
      Count
    </button>
  </Form>
</template>

<script setup>
import { Form, Field } from 'vee-validate'
import VueGoogleAutocomplete from '@/components/custom/VueGoogleAutocomplete.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { onMounted, ref } from 'vue'
import { useGoogleAddress } from '@/compose/googleAddress'
import { useDatePicker, useTimeOptions } from '@/compose/datePicker'
import * as yup from 'yup'
import { useCalcStore } from '@/stores/calc'
import moment from 'moment'

const { fields, beforeInput, focusInput, getAddressData } = useGoogleAddress('hourlyAsDirected')

const { minTime, minDate, datePicker, handleInternal, dateFormat, initialDate } = useDatePicker()

const calcStore = useCalcStore()

let extra_kms = ref(null)
let hourly = ref(2)
const { timeOptions } = useTimeOptions()
const selectedTime = ref('')
const setTime = (time) => {
  const [hours, minutes] = time.split(':').map(Number)
  if (initialDate.value) {
    let updatedDate = new Date(initialDate.value)
    updatedDate.setHours(hours, minutes)
    datePicker.value = updatedDate
  }
  selectedTime.value = time
}

const hourlySchema = yup.object({
  pickup: yup
    .string()
    .required('Pick up is a required field')
    .max(230, 'Pick up must be maximum of 230 symbols')
    .test(
      'google-complete',
      'Address must be checked in google autocomplete and country must be in EU + Switzerland',
      (value, ctx) => {
        return fields[ctx.path].valid
      }
    ),
  date_start: yup
    .string()
    .required('Date is a required field')
    .max(230, 'Date is a maximum of 230 symbols')
})

const hourlySubmit = (values) => {
  values.date_start = moment(values.date_start).format('DD.MM.YYYY HH:mm')
  calcStore.update(values)
  calcStore.loadData()
}

onMounted(() => {
  // calcStore.$reset()
  //
  // calcStore.update({type_of_service: 'hourlyAsDirected'})
})
</script>

<style>
.input {
  @apply h-max;
}
.input .dp__input {
  @apply py-5 px-12 text-silver-fog placeholder-silver-fog rounded-full border-[#6B7280];
}
.input .dp__input_icon {
  @apply px-[22px] fill-silver-fog;
}

.dp__btn.dp__button {
  display: none !important;
}
.time-option {
  background: #fff;
  box-shadow: 0 1px 3px 0 inset;
  color: #666;
  font-weight: 700;
  padding: 5px;
  margin: 2px 0;
}
.time-selected {
  background: rgba(95, 208, 82, 1) !important;
}
</style>
