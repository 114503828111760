<template>
  <Form @submit="oneWaySubmit" class="form" :validation-schema="oneWaySchema" v-slot="{ errors }">
    <div
      class="mb-8 grid gap-4 text-lg/[1.38] md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-[repeat(2,_29.6795%),_22.9108%,_14.3889%]"
    >
      <Field :name="fields.pickup.name" v-slot="{ field }">
        <vue-google-autocomplete
          @placechanged="getAddressData"
          @focus="focusInputOverload($event, 'pickup')"
          @beforeinput="beforeInputOveload($event, 'pickup')"
          classname="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
          :class="errors.pickup ? 'error' : ''"
          v-bind="field"
          :placeholder="fields.pickup.placeholder"
          types=""
          id="home_form_2_pickup"
          maxlength="230"
        >
        </vue-google-autocomplete>
      </Field>
      <Field :name="fields.dropoff.name" v-slot="{ field }">
        <vue-google-autocomplete
          @placechanged="getAddressData"
          @focus="focusInputOverload($event, 'dropoff')"
          @beforeinput="beforeInputOveload($event, 'dropoff')"
          classname="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
          :class="errors.dropoff ? 'error' : ''"
          v-bind="field"
          :placeholder="fields.dropoff.placeholder"
          types=""
          id="home_form_2_dropoff"
          maxlength="230"
        >
        </vue-google-autocomplete>
      </Field>
      <Field name="date_start" v-slot="{ field }">
        <VueDatePicker
          class="input w-full"
          placeholder="Date / Time*"
          :class="errors.date_start ? 'error' : ''"
          :preview-format="dateFormat"
          :clearable="false"
          :format="dateFormat"
          v-bind="field"
          v-model="datePicker"
          @internal-model-change="handleInternal"
          :min-date="minDate"
          :min-time="minTime"
        >
          <template v-slot:right-sidebar>
            <div class="overflow-y-scroll px-3 py-2" style="max-height: 300px">
              <div
                v-for="time in timeOptions"
                :key="time"
                @click="setTime(time)"
                :class="[
                  'time-option cursor-pointer',
                  time === selectedTime ? 'time-selected' : ''
                ]"
              >
                {{ time }}
              </div>
            </div>
          </template>
        </VueDatePicker>
      </Field>
      <Field v-if="distance" name="distance" v-slot="{ field }">
        <input
          v-bind="field"
          :value="calc.distance"
          v-model="calc.distance"
          class="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green border border-[#6B7280] focus:border-transparent text-base"
          placeholder="Distance"
        />
      </Field>
      <div class="flex items-center gap-x-4 col-span-full">
        <label class="inline-block pl-[0.15rem] hover:cursor-pointer" for="flexSwitchCheckDefault">
          Use Distance For Calculation
        </label>
        <input
          v-model="distance"
          class="h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[3px] after:h-5 after:w-5 after:rounded-full !bg-none border-none after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:!bg-lush-green checked:after:absolute checked:after:z-[2] checked:after:ml-[1.0625rem] after:ml-[-0.25rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
        />
      </div>
    </div>
    <button
      class="w-full rounded-full border border-lush-green bg-lush-green py-[22px] text-lg/[1.1] font-semibold text-midnight-ink duration-300 hover:bg-transparent dark:hover:text-white"
    >
      Count
    </button>
  </Form>
</template>

<script setup>
import { Form, Field } from 'vee-validate'
import VueGoogleAutocomplete from '@/components/custom/VueGoogleAutocomplete.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { useGoogleAddress } from '@/compose/googleAddress'
import { useDatePicker, useTimeOptions } from '@/compose/datePicker'
import * as yup from 'yup'
import { useCalcStore } from '@/stores/calc'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import moment from 'moment'

const { fields, beforeInput, focusInput, getAddressData } = useGoogleAddress('oneWayTransfer')

const { minTime, minDate, datePicker, handleInternal, dateFormat, initialDate } = useDatePicker()

const distance = ref(false)

const calcStore = useCalcStore()
const { calc } = storeToRefs(calcStore)

const { timeOptions } = useTimeOptions()
const selectedTime = ref('')
const setTime = (time) => {
  const [hours, minutes] = time.split(':').map(Number)
  if (initialDate.value) {
    let updatedDate = new Date(initialDate.value)
    updatedDate.setHours(hours, minutes)
    datePicker.value = updatedDate
  }
  selectedTime.value = time
}

const oneWaySchema = yup.object({
  pickup: yup
    .string()
    .required('Pick up is a required field')
    .max(230)
    .test(
      'google-complete',
      'Address must be checked in google autocomplete and country must be in EU + Switzerland',
      (value, ctx) => {
        return fields[ctx.path].valid
      }
    ),

  dropoff: yup
    .string()
    .required('Drop Off is a required field')
    .max(230, 'Drop Off is a maximum of 230 symbols')
    .test(
      'google-complete',
      'Address must be checked in google autocomplete and country must be in EU + Switzerland',
      (value, ctx) => {
        return fields[ctx.path].valid
      }
    ),
  date_start: yup
    .string()
    .required('Date is a required field')
    .max(230, 'Date is a maximum of 230 symbols')
})

const beforeInputOveload = (event, fieldName) => {
  //calc.value.distance = null
  distance.value = false
  beforeInput(event, fieldName)
}

const focusInputOverload = (event, fieldName) => {
  //calc.value.distance = null
  distance.value = false
  focusInput(event, fieldName)
}

const oneWaySubmit = (values) => {
  values.date_start = moment(values.date_start).format('DD.MM.YYYY HH:mm')
  if (!distance.value) {
    calc.value.distance = null
  }
  calcStore.update(values)
  calcStore.loadData()
}

onMounted(() => {
  // calcStore.$reset()
  // calcStore.update({type_of_service: 'oneWayTransfer'})
})
</script>

<style>
.input {
  @apply h-max;
}

.input .dp__input {
  @apply py-5 px-12 text-silver-fog placeholder-silver-fog rounded-full border-[#6B7280];
}

.input .dp__input_icon {
  @apply px-[22px] fill-silver-fog;
}

.dp__btn.dp__button {
  display: none !important;
}

.time-option {
  background: #fff;
  box-shadow: 0 1px 3px 0 inset;
  color: #666;
  font-weight: 700;
  padding: 5px;
  margin: 2px 0;
}

.time-selected {
  background: rgba(95, 208, 82, 1) !important;
}
</style>
