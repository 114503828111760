<script setup></script>

<template>
  <svg
    class="absolute right-1.5 stroke-neutral-500 opacity-0 duration-300"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 10L12 15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 15L17 10" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<style scoped></style>
