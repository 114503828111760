<script setup>
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { FwbButton, FwbModal } from 'flowbite-vue'

// eslint-disable-next-line no-console

// replaced dyanmicaly
const reloadSW = '__RELOAD_SW__'

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    // eslint-disable-next-line no-console
    console.log(`Service Worker at: ${swUrl}`)
    if (reloadSW === 'true') {
      r &&
        setInterval(async () => {
          // eslint-disable-next-line no-console
          console.log('Checking for sw update', r)
          await r.update()
        }, 20000 /* 20s for testing purposes */)
    } else {
      // eslint-disable-next-line no-console
      console.log(`SW Registered:`, r)
    }
  }
})

async function close() {
  offlineReady.value = false
  needRefresh.value = false
}
</script>

<template>
  <fwb-modal v-if="needRefresh" @close="close">
    <template #body>
      <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        New content available, click on reload button to update.
      </p>
    </template>
    <template #footer>
      <div class="flex justify-between">
        <fwb-button @click="close" color="alternative"> Close </fwb-button>
        <fwb-button @click="updateServiceWorker()" color="green"> Reload </fwb-button>
      </div>
    </template>
  </fwb-modal>
</template>

<style></style>
