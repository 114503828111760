<template>
  <section class="mb-8 grid gap-[44px] text-lg/[1.38] sm:grid-cols-2 md:flex lg:gap-[88px]">
    <article v-if="calc.pickup" class="flex flex-col gap-2 max-md:w-full">
      <h3 class="text-ghost-gray">Pick Up Location:</h3>
      <p>{{ calc.pickup }}</p>
    </article>
    <article v-if="calc.dropoff" class="flex flex-col gap-2 max-md:w-full">
      <h3 class="text-ghost-gray">Drop-Off Location:</h3>
      <p>{{ calc.dropoff }}</p>
    </article>
    <article v-if="calc.hours" class="flex flex-col gap-2 max-md:w-full">
      <h3 class="text-ghost-gray">Hours:</h3>
      <p>{{ calc.hours }} hours</p>
    </article>
    <article v-if="calc.extra_kms" class="flex flex-col gap-2 max-md:w-full">
      <h3 class="text-ghost-gray">Extra Kms:</h3>
      <p>{{ calc.extra_kms }} KMS</p>
    </article>
    <article v-if="calc.date_start" class="flex flex-col gap-2 max-md:w-full">
      <h3 class="text-ghost-gray">Date:</h3>
      <p>{{ calc.date_start }}</p>
    </article>

    <article v-if="calc.distance" class="flex flex-col gap-2 max-md:w-full">
      <h3 class="text-ghost-gray">Distance:</h3>
      <p>{{ calc.distance }} KMS</p>
    </article>
  </section>
</template>

<script setup>
import { useCalcStore } from '@/stores/calc'
import { storeToRefs } from 'pinia'

const calcStore = useCalcStore()
const { calc } = storeToRefs(calcStore)
</script>

<style scoped></style>
