<template>
  <main
    :class="menuCollapsed ? 'md:pl-[140px]' : 'md:pl-[284px]'"
    class="pl-6 pr-6 pt-[70px] text-sm/[1.42] lg:text-lg/[1.38] duration-300"
  >
    <h1 class="mb-9 text-[40px] font-bold leading-[1.1] lg:text-[80px]">Users</h1>
    <form action="" class="w-full md:w-2/3 lg:w-1/2 space-y-16">
      <div class="grid gap-4">
        <label class="space-y-2">
          <span>First name</span>
          <input
            name="first_name"
            class="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
            placeholder="…"
            v-model="userData.first_name"
          />
        </label>
        <label class="space-y-2">
          <span>Last name</span>
          <input
            name="last_name"
            class="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
            placeholder="…"
            v-model="userData.last_name"
          />
        </label>
        <label class="space-y-2">
          <span>Email</span>
          <input
            name="email"
            class="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
            placeholder="…"
            v-model="userData.email"
          />
        </label>
        <label class="space-y-2">
          <span>Password</span>
          <input
            name="password"
            class="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
            placeholder="…"
            v-model="userData.password"
          />
        </label>
      </div>
      <button
        @click.prevent="userSubmit"
        class="w-full rounded-full border border-lush-green bg-lush-green py-[22px] font-semibold leading-[1.1] text-midnight-ink duration-300 disabled:bg-transparent disabled:text-white hover:bg-transparent hover:text-white"
      >
        Create
      </button>
    </form>
  </main>
</template>

<script setup>
import { inject, onBeforeMount, ref } from 'vue'
import { useFetcher } from '@/compose/axios'
import router from '@/router'

const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
const menuCollapsed = inject('menuCollapsed')

const userData = ref({
  first_name: null,
  last_name: null,
  email: null,
  password: null
})

const userSubmit = () => {
  axiosInstance.post('/admin/users/create', userData.value).then((response) => {
    if (response.data.status === 'success') {
      //TODO make some modal or success point
      router.push({ name: 'users' })
    }
  })
}

onBeforeMount(() => {})
</script>
