<template>
  <fwb-modal v-if="isShowModal" @close="modalStore.closeModal">
    <template #header>
      <div class="flex items-center text-lg"></div>
    </template>
    <template #body>
      <p
        v-if="modalType === 'areaDelete'"
        class="text-base leading-relaxed text-gray-500 dark:text-gray-400"
      >
        Are you really want to delete area {{ modalData.id }} ?
      </p>
      <setRadius v-if="modalType === 'setRadius'"></setRadius>
      <setPolygon v-if="modalType === 'setPolygon'"></setPolygon>
    </template>
    <template #footer>
      <div class="flex justify-between">
        <fwb-button @click="modalStore.closeModal" color="alternative"> Decline </fwb-button>
        <fwb-button @click="modalAction" color="green"> I accept </fwb-button>
      </div>
    </template>
  </fwb-modal>
</template>

<script setup>
import { FwbButton, FwbModal } from 'flowbite-vue'
import setRadius from '@/components/modals/templates/setRadius.vue'
import setPolygon from '@/components/modals/templates/setPolygon.vue'
import { useModalStore } from '@/stores/modal'
import { storeToRefs } from 'pinia'

const modalStore = useModalStore()
const { modalData, modalType, isShowModal } = storeToRefs(modalStore)
const emit = defineEmits(['action'])

function modalAction() {
  emit('action')
  modalStore.closeModal()
}
</script>
