<template>
  <h1 class="flex items-end gap-x-6 mb-9 text-[40px] font-bold leading-[1.1] lg:text-[80px]">
    Roles
  </h1>
  <section class="relative">
    <div class="overflow-hidden" id="headerdiv">
      <table id="headertable" class="w-full">
        <thead>
          <!-- Overlay -->
          <tr
            :class="{ 'absolute w-full h-full z-10 rounded-t-md bg-gray-600/20': requestTime }"
          ></tr>
        </thead>
      </table>
    </div>
    <div
      class="overflow-y-scroll max-h-[100vh]"
      onscroll="document.getElementById('headerdiv').scrollLeft = this.scrollLeft;"
    >
      <table id="bodytable" class="w-full">
        <tbody class="divide-y divide-silver-fog text-center">
          <tr class="gap-x-2">
            <td class="w-full">
              <input
                class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                placeholder="Role"
                type="text"
                @input="roleSearch"
              />
            </td>
            <td>
              <p>Action</p>
            </td>
          </tr>
          <tr v-for="(role, index) in findRoles" :key="index">
            <td>{{ role.name }}</td>
            <td class="flex justify-center">
              <button
                class="block rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5 m-2 w-[100px]"
                @click="applyRole(role)"
                type="button"
              >
                {{ userRoles.find((x) => x.name == role.name) ? 'Active' : 'Unactive' }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import { useFetcher } from '@/compose/axios'
import { usehasClassInHierarchy } from '@/compose/hasClassInHierarchy'
import { useRoute } from 'vue-router'

const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })

const roles = ref([])
const findRoles = ref([])

const route = useRoute()

const props = defineProps(['userRoles'])
const userRoles = ref(props.userRoles)

const { hasClassInHierarchy } = usehasClassInHierarchy()

const hideAllMultiselectOptions = (event) => {
  if (!hasClassInHierarchy(event.target, 'multiselect')) {
    document.querySelectorAll('.multiselect .options').forEach((multiselectOptions) => {
      multiselectOptions.classList.add('hidden')
    })
  }
}

const requestTime = ref(false)

const roleSearch = ({ target }) => {
  if (target.value == '') {
    findRoles.value = roles.value
  } else {
    findRoles.value = roles.value.filter((element) => {
      return element.name.includes(target.value)
    })
  }
}

const applyRole = (role) => {
  const id = route.params.id
  requestTime.value = true

  let data = {
    role: role.id,
    roleDelete: false
  }

  axiosInstance
    .post('/admin/users/role/' + id, data)
    .then((response) => {
      userRoles.value = response.data.data.roles
      requestTime.value = false
    })
    .catch((error) => {
      console.log(error)
      requestTime.value = false
    })
}

onBeforeMount(() => {
  axiosInstance
    .get('/admin/roles')
    .then((response) => {
      if (response.data?.status == 'success') {
        roles.value = response?.data?.data?.roles
        findRoles.value = roles.value
      }
    })
    .catch((error) => {
      console.log(error)
    })
})

window.addEventListener('click', hideAllMultiselectOptions)
</script>

<style scoped>
table tbody tr:nth-child(2n) {
  background-color: #2d3135;
}
</style>
