<template>
  <svg
    class="stroke-black dark:stroke-white"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.002" cy="16.002" r="4.74089" stroke-width="2" stroke-linejoin="round" />
    <path d="M25.4838 16.002H26.669" stroke-width="2" stroke-linecap="round" />
    <path d="M5.335 16.002H6.52022" stroke-width="2" stroke-linecap="round" />
    <path d="M16.002 25.4838L16.002 26.669" stroke-width="2" stroke-linecap="round" />
    <path d="M16.002 5.335L16.002 6.52022" stroke-width="2" stroke-linecap="round" />
    <path d="M22.7066 22.7066L23.5447 23.5447" stroke-width="2" stroke-linecap="round" />
    <path d="M8.45929 8.45929L9.29737 9.29737" stroke-width="2" stroke-linecap="round" />
    <path d="M9.29737 22.7066L8.45929 23.5447" stroke-width="2" stroke-linecap="round" />
    <path d="M23.5447 8.45929L22.7066 9.29737" stroke-width="2" stroke-linecap="round" />
  </svg>
</template>

<script setup></script>

<style scoped></style>
