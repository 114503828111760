<template>
  <svg
    class="fill-white"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6636 15.66L27.6631 15.6285C27.6534 15.3186 27.5004 15.0308 27.249 14.8493C26.9976 14.6678 26.6763 14.6131 26.379 14.7014L26.6636 15.66ZM16.344 5.34038L17.3026 5.62497C17.3909 5.32773 17.3362 5.00637 17.1547 4.75499C16.9732 4.5036 16.6854 4.35063 16.3755 4.34087L16.344 5.34038ZM26.379 14.7014C25.7209 14.8968 25.0229 15.002 24.2986 15.002V17.002C25.2177 17.002 26.1073 16.8683 26.9482 16.6187L26.379 14.7014ZM24.2986 15.002C20.2688 15.002 17.002 11.7352 17.002 7.70545H15.002C15.002 12.8398 19.1642 17.002 24.2986 17.002V15.002ZM17.002 7.70545C17.002 6.98109 17.1072 6.28308 17.3026 5.62497L15.3853 5.05578C15.1357 5.89669 15.002 6.78633 15.002 7.70545H17.002ZM16.002 6.335C16.1059 6.335 16.2094 6.33664 16.3125 6.33988L16.3755 4.34087C16.2514 4.33697 16.1269 4.335 16.002 4.335V6.335ZM6.335 16.002C6.335 10.6631 10.6631 6.335 16.002 6.335V4.335C9.55849 4.335 4.335 9.55849 4.335 16.002H6.335ZM16.002 25.669C10.6631 25.669 6.335 21.3409 6.335 16.002H4.335C4.335 22.4455 9.55849 27.669 16.002 27.669V25.669ZM25.669 16.002C25.669 21.3409 21.3409 25.669 16.002 25.669V27.669C22.4455 27.669 27.669 22.4455 27.669 16.002H25.669ZM25.6641 15.6915C25.6674 15.7946 25.669 15.8981 25.669 16.002H27.669C27.669 15.8771 27.667 15.7526 27.6631 15.6285L25.6641 15.6915Z"
    />
  </svg>
</template>

<script setup></script>

<style scoped></style>
