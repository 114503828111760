<template>
  <fwb-modal v-if="isShowModal" @close="closeModal">
    <template #header>
      <div class="flex items-center text-lg"></div>
    </template>
    <template #body>
      <p
        v-if="modalData.type === 'archiveDiscount'"
        class="text-base leading-relaxed text-gray-500 dark:text-gray-400"
      >
        Are you really want to archive discount Prog ID
        {{ modalData.entity.id }}
      </p>
      <p
        v-if="modalData.type === 'removeDiscount'"
        class="text-base leading-relaxed text-gray-500 dark:text-gray-400"
      >
        Are you really want to delete discount Prog ID
        {{ modalData.entity.id }}
      </p>
    </template>
    <template #footer>
      <div class="flex justify-between">
        <fwb-button @click="closeModal" color="alternative"> Decline </fwb-button>
        <fwb-button @click="modalAction" color="green"> I accept </fwb-button>
      </div>
    </template>
  </fwb-modal>
</template>

<script setup>
import { FwbButton, FwbModal } from 'flowbite-vue'
const props = defineProps({
  isShowModal: Boolean,
  modalData: Object
})

const emit = defineEmits(['action', 'close'])

function closeModal() {
  emit('close')
}

function modalAction() {
  emit('action', {
    type: props.modalData.type,
    entity: props.modalData.entity
  })
  closeModal()
}
</script>
