<template>
  <div class="flex justify-end items-end gap-2.5 m-4">
    <div
      v-if="isShown"
      class="break-all h-full px-5 py-2.5 rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel"
    >
      ver-{{ version }}
    </div>
    <button @click="showVersion" class="h-full p-1.5 rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel">
      <GitIcon />
    </button>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import GitIcon from '@/components/icons/GitIcon.vue'

const isShown = ref(false)
const version = computed(() => {
  return import.meta.env.VITE_PROJECT_VERSION
})

const showVersion = () => (isShown.value = !isShown.value)
</script>

<style scoped></style>
