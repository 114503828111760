import { defineStore } from 'pinia'
import { useFetcher } from '@/compose/axios'
import { ref } from 'vue'

const STORE_NAME = 'rides'
const LOCALSTORAGE_KEY = 'rides'

export const useRidesStore = defineStore(STORE_NAME, () => {
  const ridesHistoryRequests = ref(null)
  const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })

  function $reset() {
    ridesHistoryRequests.value = null
    sessionStorage.removeItem(LOCALSTORAGE_KEY)
  }

  function update(data) {
    ridesHistoryRequests.value = data
  }

  function loadData() {
    axiosInstance.get('/admin/rides-histories/requests').then((response) => {
      update(response.data.data)
    })
  }

  const statusChosen = async (requestId, status) => {
    await axiosInstance
      .post('/admin/rides-histories/requests', {
        status: status,
        request_id: requestId
      })
      .then(() => {
        loadData()
      })
  }

  loadData()

  return { update, $reset, loadData, ridesHistoryRequests, statusChosen }
})
