<script setup></script>

<template>
  <svg
    :class="{
      'stroke-black dark:stroke-white': !active,
      'stroke-lush-green': active,
      'duration-300': true
    }"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.27275 7.74025L16 4L24.7273 7.74025C25.0949 7.89783 25.3333 8.25937 25.3333 8.6594V19.1847C25.3333 21.3379 24.1796 23.3259 22.3102 24.3942L16 28L9.68983 24.3942C7.82038 23.3259 6.66667 21.3379 6.66667 19.1847V8.6594C6.66667 8.25937 6.90506 7.89783 7.27275 7.74025Z"
      :stroke="active ? 'var(--color-lush-green)' : 'var(--color-black)'"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      v-if="active"
      d="M20 13.3333L14.6667 18.6667L12 16"
      stroke="var(--color-lush-green)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style scoped></style>
