<template>
  <button
    @click="mainStore.update(mode === 'dark' ? 'light' : 'dark')"
    class="flex justify-center items-center w-[46px] h-[46px] rounded-lg"
  >
    <DarkIcon v-if="mode === 'dark'" />
    <LightIcon v-if="mode === 'light'" />
  </button>
</template>

<script setup>
import DarkIcon from '@/components/icons/DarkIcon.vue'
import LightIcon from '@/components/icons/LightIcon.vue'
import { useMainStore } from '@/stores/main'
import { storeToRefs } from 'pinia'

const mainStore = useMainStore()
const { mode } = storeToRefs(mainStore)
</script>
