<template>
  <main
    :class="menuCollapsed ? 'md:pl-[140px]' : 'md:pl-[284px]'"
    class="pl-6 pr-6 pt-[70px] pb-[140px] duration-300"
  >
    <h1 class="mb-9 text-[40px] font-bold leading-[1.1] lg:text-[80px]">Get price</h1>
    <section class="mb-8 rounded-[40px] dark:bg-steel-gray px-6 pb-4 pt-8 shadow-lg">
      <h2 class="mb-4 pl-2 text-2xl/[1.1]">Choose Type of Rent</h2>
      <div
        class="typesOfRent mb-[42px] grid w-full gap-2 rounded-[28px] border p-2 text-lg/[1.38] md:w-max md:grid-cols-[repeat(2,_202.5px)] md:rounded-full"
      >
        <button
          @click="setTypeOfService('oneWayTransfer')"
          :class="{
            'rounded-full py-2.5  duration-300': true,
            'bg-fresh-mint text-midnight-ink': activeClass('oneWayTransfer')
          }"
        >
          One Way Transfer
        </button>
        <button
          @click="setTypeOfService('hourlyAsDirected')"
          :class="{
            'rounded-full py-2.5 duration-300': true,
            'bg-fresh-mint text-midnight-ink': activeClass('hourlyAsDirected')
          }"
        >
          Hourly as Directed
        </button>
      </div>
      <OneWayTransferForm v-if="calc.type_of_service === 'oneWayTransfer'" />
      <HourlyAsDirectedForm v-if="calc.type_of_service === 'hourlyAsDirected'" />
    </section>
    <CalcData />
    <CalcCars v-if="calcData" />
  </main>
</template>

<script setup>
import { inject, onMounted } from 'vue'
import '@vuepic/vue-datepicker/dist/main.css'
import { useCalcStore } from '@/stores/calc'
import { storeToRefs } from 'pinia'
import OneWayTransferForm from '@/components/forms/OneWayTransferForm.vue'
import HourlyAsDirectedForm from '@/components/forms/HourlyAsDirectedForm.vue'
import CalcData from '@/components/CalcData.vue'
import CalcCars from '@/components/CalcCars.vue'

const calcStore = useCalcStore()

const activeClass = (type) => {
  return calc.value.type_of_service === type
}

const { calc, calcData } = storeToRefs(calcStore)

const setTypeOfService = (type) => {
  calcStore.$reset()
  // calcStore.update({type_of_service: 'oneWayTransfer'})
  calcStore.update({ type_of_service: type })
}

const menuCollapsed = inject('menuCollapsed')

onMounted(() => {})
</script>
